import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as R from 'ramda';

import {
  addPaymentMethod,
  createCharge,
  fetchOrder,
  getPaymentActionResult,
} from '@/requests';
import {
  AsyncResponse,
  CreateChargeResponse,
  Order,
  UpdatePaymentMethodResponse,
} from '@/types';
import { camelCaseKeys } from '@/utils';

import { rtkQueryError } from '../store.utils';
import {
  POLLING_INTERVAL,
  POLLING_TIMEOUT_LIMIT,
} from './payment-api.constants';

export const transformAsyncResponse = <Response>(
  response: any,
): AsyncResponse<Response> =>
  R.pipe(camelCaseKeys, e => ({
    asyncResultId: e.asyncResultId,
    data: e.data,
    isComplete: !!e.data,
  }))(response);

export const pollQuery = async ({
  baseQuery,
  query,
}: {
  baseQuery: (
    arg: string | FetchArgs,
  ) => Promise<QueryReturnValue<any[], FetchBaseQueryError, {}>>;
  query: any;
}): Promise<QueryReturnValue<any, FetchBaseQueryError, {}>> => {
  let pollingCompleted = false;
  let elapsed = 0;
  let result;
  while (!pollingCompleted && elapsed < POLLING_TIMEOUT_LIMIT) {
    const { data, error } = await baseQuery(query);
    result = data?.[0];

    if (error) return { error };
    if (result?.data?.error) {
      return rtkQueryError(result?.data);
    }

    pollingCompleted = !!result;
    if (!pollingCompleted)
      await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
    elapsed += POLLING_INTERVAL;
  }

  if (!pollingCompleted)
    return rtkQueryError('Timeout while polling for result');

  return { data: result };
};

export const addPaymentMethodPoll = async ({
  baseQuery,
  token,
}: {
  baseQuery: (
    arg: string | FetchArgs,
  ) => Promise<QueryReturnValue<any, FetchBaseQueryError, {}>>;
  token: string;
}): Promise<
  QueryReturnValue<
    AsyncResponse<UpdatePaymentMethodResponse>,
    FetchBaseQueryError,
    {}
  >
> => {
  const { data: { async_result_id: asyncResultId } = {}, error } =
    await baseQuery(addPaymentMethod({ token }));

  if (error) return { error };

  const query = getPaymentActionResult({ asyncResultId });
  const { data: response, error: getPaymentActionResultError } =
    await pollQuery({ baseQuery, query });
  if (getPaymentActionResultError) {
    return { error: getPaymentActionResultError };
  }
  return { data: transformAsyncResponse(response) };
};

export const createChargePoll = async ({
  baseQuery,
  sku,
  province,
}: {
  baseQuery: (
    arg: string | FetchArgs,
  ) => Promise<QueryReturnValue<any, FetchBaseQueryError, {}>>;
  sku: string;
  province: string;
}): Promise<
  QueryReturnValue<AsyncResponse<CreateChargeResponse>, FetchBaseQueryError, {}>
> => {
  const {
    data: { async_result_id: asyncResultId, order_id: orderId } = {},
    error,
  } = await baseQuery(createCharge({ sku, province }));

  if (error) return { error };

  const query = getPaymentActionResult({ asyncResultId });
  const { data: response, error: createChargePollError } = await pollQuery({
    baseQuery,
    query,
  });
  if (createChargePollError) return { error: createChargePollError };

  return {
    data: camelCaseKeys({ ...response, data: { ...response.data, orderId } }),
  };
};

export const getOrderPoll = async ({
  baseQuery,
  orderId,
}: {
  baseQuery: (
    arg: string | FetchArgs,
  ) => Promise<QueryReturnValue<any, FetchBaseQueryError, {}>>;
  orderId: number;
}): Promise<QueryReturnValue<Order, FetchBaseQueryError, {}>> => {
  const query = fetchOrder({ orderId });
  const { data: response, error: getOrderError } = await pollQuery({
    baseQuery,
    query,
  });
  if (getOrderError) return { error: getOrderError };
  return { data: camelCaseKeys(response) };
};
