import { PlayArrow, Share } from '@mui/icons-material';
import { Box, Button, Hidden, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppFooter, EpisodeDetails } from '@/containers';
import { AnalyticsService } from '@/services';
import {
  playerThunks,
  selectIsPlayerPlaying,
  useFetchCPACanadaEpisodeQuery,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import {
  CPAContentEndModal,
  CPAHeader,
  CPAPlayer,
  CPASignUpModal,
} from './containers';

export const CPACanada = () => {
  const [episodeStarted, setStarted] = useState(false);
  const { data: cpaCanadaContent } = useFetchCPACanadaEpisodeQuery();
  const dispatch = useDispatch();
  const isPlaying = useSelector(selectIsPlayerPlaying);

  const playEpisode = () => {
    dispatch(
      isPlaying ? playerThunks.pausePlayer() : playerThunks.playPlayer(),
    );

    if (!episodeStarted) {
      const identifier = cpaCanadaContent.episode.contentId;
      AnalyticsService.episodeStart({
        chapter: {
          chapterId: identifier,
          name: identifier,
        },
        episode: {
          episodeId: identifier,
          name: identifier,
          duration: cpaCanadaContent.episode.duration,
        },
        isNasba: false,
        ref: { page: 'Cpa Canada', button: 'banner play' },
      });
    }

    setStarted(true);
  };

  return (
    <Box sx={styles.pageContainer}>
      <CPASignUpModal cpaCanadaContent={cpaCanadaContent} />
      <CPAContentEndModal cpaCanadaContent={cpaCanadaContent} />
      <Box sx={styles.fullWidthBox}>
        <CPAHeader cpaCanadaContent={cpaCanadaContent} />
        {episodeStarted && <CPAPlayer cpaCanadaContent={cpaCanadaContent} />}
        {!!cpaCanadaContent?.episode && (
          <EpisodeDetails
            episode={cpaCanadaContent.episode}
            bannerActions={
              <Box>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={playEpisode}
                  sx={styles.playButton}>
                  <PlayArrow />
                  <Hidden smDown>
                    <Typography>Play Now</Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography>Play</Typography>
                  </Hidden>
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    SnackbarUtils.success('Link copied to clipboard');
                  }}
                  sx={styles.shareButton}>
                  <Share sx={{ mr: 1 }} />
                  Share
                </Button>
              </Box>
            }
          />
        )}
      </Box>
      <AppFooter />
    </Box>
  );
};

const styles = {
  pageContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 'calc(1360px + 1.875rem)',
    background: 'white',
    backgroundColor: 'white',
  },
  fullWidthBox: theme => ({
    margin: 6,
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.typography.pxToRem(-10)}`,
    },
  }),
  playButton: {
    p: 1.5,
    background: theme => theme.gradients.orange,
  },
  shareButton: {
    p: 1.5,
    ml: 2,
    color: 'white',
  },
};
