import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { EpisodeCard, EpisodeCardSkeleton } from '@/containers';

import {
  EPISODE_GRID_CHUNK_SIZE,
  EPISODE_GRID_MARGIN,
  SCROLL_THRESHOLD,
} from './constants';

export const EpisodeGrid = ({ episodeIds = [], isLoading, referrer }) => {
  const [visibleEpisodeCount, setVisibleEpisodeCount] = useState(
    EPISODE_GRID_CHUNK_SIZE,
  );

  useEffect(() => {
    const loadMore = () => {
      if (visibleEpisodeCount >= episodeIds.length) return;

      const currentScrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const scrollThreshold =
        document.scrollingElement.scrollHeight - SCROLL_THRESHOLD;

      if (currentScrollPosition >= scrollThreshold) {
        setVisibleEpisodeCount(
          Math.min(
            visibleEpisodeCount + EPISODE_GRID_CHUNK_SIZE,
            episodeIds.length,
          ),
        );
      }
    };

    window.addEventListener('scroll', loadMore);
    return () => window.removeEventListener('scroll', loadMore);
  }, [visibleEpisodeCount, episodeIds.length]);

  const visibleEpisodeIds = episodeIds.slice(0, visibleEpisodeCount);

  return (
    <Box sx={styles.cardGrid}>
      {!isLoading
        ? visibleEpisodeIds?.map((episodeId, index) => (
            <Box sx={styles.cardContainer} key={episodeId}>
              <EpisodeCard
                adjustWidth={true}
                episodeId={episodeId}
                referrer={{ ...referrer, position: index }}
              />
            </Box>
          ))
        : [...Array(8).keys()].map(i => (
            <Box sx={styles.cardContainer} key={i}>
              <EpisodeCardSkeleton includeText={true} />
            </Box>
          ))}
    </Box>
  );
};

const styles = {
  cardContainer: {
    display: 'inline-block',
    mt: 1.75,
    mb: 2.75,
    mx: 'auto',
  },
  cardGrid: theme => ({
    display: 'grid',
    justifyContent: {
      xs: 'space-around',
      md: 'space-between',
    },
    gridTemplateColumns: {
      xs: 'repeat(2, 50%)',
      md: `repeat(auto-fill,${theme.dimensions.episodeCardCover.width + EPISODE_GRID_MARGIN}px)`,
    },
  }),
};
