import { Box } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { CheckboxButton } from '@/components';
import { UsaStates } from '@/constants';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { HighlightedText } from './HighlightedText';
import { ModalTitle } from './ModalTitle';

export const UsaCpaStatesForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState({});

  const handleChange = id => {
    const checked = !!form[id];

    setForm(prevState =>
      checked
        ? R.omit([id], prevState)
        : {
            ...prevState,
            [id]: true,
          },
    );
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} />
      <Box sx={styles.formPrimarySection}>
        {Object.entries(UsaStates).map(([key, name]) => (
          <Box key={key} sx={styles.checkboxContainer}>
            <CheckboxButton
              label={name}
              id={key}
              checked={!!form[key]}
              onChange={() => handleChange(key)}
            />
          </Box>
        ))}
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={() => goToNextStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </Box>
  );
};

const isFormValid = form => Object.keys(form).length > 0;

const Title = () => {
  return (
    <ModalTitle>
      As a <HighlightedText>CPA</HighlightedText> in the{' '}
      <HighlightedText>United States of America</HighlightedText>, select all
      states you are licensed in.
    </ModalTitle>
  );
};
