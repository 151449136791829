import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export const saveCertificateZip = async ({ certificateFiles, summaryFile }) => {
  const zip = new JSZip();

  const certificateFolder = zip.folder('certificates');
  certificateFiles.forEach(file =>
    certificateFolder.file(file.name, file.content),
  );

  zip.file('summary.pdf', summaryFile);

  const zipBlob = await zip.generateAsync({ type: 'blob' });
  saveAs(zipBlob, 'summary.zip');
};
