import * as R from 'ramda';

import { PERSONAL_OFFER } from '@/containers/Pricing/constants';
import {
  addPaymentMethod,
  fetchTaxRates,
  getMyOffers,
  getOffers,
  getPaymentActionResult,
  getPaymentMethods,
  submitPurchaseFeedback,
} from '@/requests';
import { rtkQueryError } from '@/store/store.utils';
import {
  AsyncResponse,
  Offer,
  Order,
  PaymentMethod,
  TaxRate,
  UpdatePaymentMethodResponse,
} from '@/types';
import { camelCaseKeys } from '@/utils';

import { nodeApi } from '../node-api';
import { postgrestApi } from '../postgrest-api';
import { PostgrestApiTags } from '../store.constants';
import {
  addPaymentMethodPoll,
  createChargePoll,
  getOrderPoll,
  transformAsyncResponse,
} from './payment-api.utils';

type PurchasePackageArgs = { sku: string; province: string; token: string };
type PurchaseFeedbackArgs = { heardAboutUs: string; otherText: string };

export const paymentApiPostgrest = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    addPaymentMethod: builder.mutation<
      { asyncResultId: string },
      { token: string }
    >({
      query: addPaymentMethod,
      transformResponse: (response: any) => camelCaseKeys(response),
    }),
    purchasePackage: builder.mutation<Order, PurchasePackageArgs>({
      queryFn: async (
        { sku, province, token },
        redux,
        extraOptions,
        baseQuery,
      ) => {
        try {
          // Add payment method
          const { error: addPaymentMethodError } = await addPaymentMethodPoll({
            token,
            baseQuery,
          });
          if (addPaymentMethodError) return { error: addPaymentMethodError };

          // Create charge
          const { data, error: createChargeError } = await createChargePoll({
            sku,
            province,
            baseQuery,
          });
          if (createChargeError) return { error: createChargeError };

          const {
            data: { orderId },
          } = data;

          // Wait until plan is created/updated
          const { data: order, error: getOrderError } = await getOrderPoll({
            orderId,
            baseQuery,
          });
          if (getOrderError) return { error: getOrderError };

          return { data: order };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      invalidatesTags: [
        PostgrestApiTags.PLAN,
        PostgrestApiTags.USER_TAGS,
        PostgrestApiTags.PAYMENT_METHODS,
      ],
    }),
    getPaymentActionResult: builder.query<
      AsyncResponse<UpdatePaymentMethodResponse>,
      { asyncResultId: string }
    >({
      query: getPaymentActionResult,
      transformResponse: (response: any) =>
        transformAsyncResponse(response?.[0] || {}),
    }),
    // TODO typing rest of this
    getPaymentMethods: builder.query<PaymentMethod | undefined, void>({
      query: getPaymentMethods,
      transformResponse: (response: any) => camelCaseKeys(response?.[0]),
      providesTags: [PostgrestApiTags.PAYMENT_METHODS],
    }),
    getOffers: builder.query<Offer[], { skus: Offer[] }>({
      query: ({ skus }) => getOffers({ skus: skus.map((sku: any) => sku.sku) }),
      transformResponse: (response: any, _, { skus }: any) => {
        return skus.map((sku: any) => ({
          ...sku,
          price:
            (
              response?.find((offer: any) => offer.sku === sku.sku) || {
                price: 0,
              }
            ).price / 100,
        }));
      },
    }),
    getMyOffers: builder.query<Offer | undefined, void>({
      query: getMyOffers,
      transformResponse: (response: any) => {
        const offer = response?.[0];
        return !!offer
          ? {
              ...PERSONAL_OFFER,
              sku: offer.sku,
              price: offer.price / 100,
              discount: offer.discount / 100,
            }
          : undefined;
      },
    }),
    fetchTaxRates: builder.query<Record<string, TaxRate>, void>({
      query: fetchTaxRates,
      transformResponse: (response: any): Record<string, TaxRate> =>
        R.pipe(R.map(camelCaseKeys), R.indexBy(R.prop('taxRegion')))(response),
    }),
  }),
});

export const paymentApiNode = nodeApi.injectEndpoints({
  endpoints: builder => ({
    submitPurchaseFeedback: builder.mutation<void, PurchaseFeedbackArgs>({
      query: submitPurchaseFeedback,
    }),
  }),
});

export const {
  useAddPaymentMethodMutation,
  usePurchasePackageMutation,
  useGetPaymentActionResultQuery,
  useGetPaymentMethodsQuery,
  useGetOffersQuery,
  useGetMyOffersQuery,
  useFetchTaxRatesQuery,
} = paymentApiPostgrest;

export const { useSubmitPurchaseFeedbackMutation } = paymentApiNode;
