import { getCurrentScope } from '@sentry/react';
import LogRocketConnection from 'logrocket';

import config from '@/config';
import { urls } from '@/constants';

import { useInitHook } from './useInitHook';

export const LogRocket = () => {
  useInitHook(({ userProfile, userId, userRegion }) => {
    LogRocketConnection.init(config.LOGROCKET_PROJECT_KEY, {
      ingestServer: urls.actions.logrocket,
    });

    const logRocketData = {
      name: userProfile
        ? `${userProfile.firstName} ${userProfile.lastName}`
        : '-',
      email: userProfile?.email,
      isNasba: userRegion === 'US',
      isAnonymous: !userId,
    };
    if (logRocketData.isAnonymous) {
      LogRocketConnection.identify(logRocketData);
    } else {
      LogRocketConnection.identify(userId, logRocketData);
    }

    LogRocketConnection.getSessionURL(sessionURL => {
      getCurrentScope().setExtra('sessionURL', sessionURL);
    });
  });
  return null;
};
