import { ALL_REGION, ALL_TOPIC } from '@/constants';
import {
  fetchTechnicalSearchOptionsRequest,
  fetchTechnicalSearchResultRequest,
} from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';

import { technicalSearchEpisodeTransform } from './technical-search-api.utils';

export const technicalSearchApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchTechnicalSearchCategories: builder.query({
      query: () =>
        fetchTechnicalSearchOptionsRequest({ optionType: 'category' }),
      transformResponse: res => res.map(r => r.value),
    }),
    fetchTechnicalSearchRegions: builder.query({
      query: () => fetchTechnicalSearchOptionsRequest({ optionType: 'region' }),
      transformResponse: res => [ALL_REGION, ...res.map(r => r.value)],
    }),
    fetchTechnicalSearchTopics: builder.query({
      query: fetchTechnicalSearchResultRequest,
      transformResponse: res => [
        ALL_TOPIC,
        ...(res.grouped_episode_tags?.find(gr => gr.type === 'topic')?.values ??
          []),
      ],
    }),
    fetchTechnicalSearchEpisodes: builder.query({
      query: fetchTechnicalSearchResultRequest,
      transformResponse: res =>
        res.matching_episodes?.map(technicalSearchEpisodeTransform) ?? [],
    }),
  }),
});

export const {
  useFetchTechnicalSearchCategoriesQuery,
  useFetchTechnicalSearchRegionsQuery,
  useFetchTechnicalSearchTopicsQuery,
  useFetchTechnicalSearchEpisodesQuery,
} = technicalSearchApi;
