import { Box, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Variants } from './constants';

export const Link = ({
  to,
  children,
  variant = Variants.CLASSIC,
  inline = false,
  color,
  ...props
}) => {
  const theme = useTheme();

  if (!to) return children;

  return (
    <Box
      sx={[
        variant === Variants.PLAIN && {
          color: 'inherit',
        },
        variant === Variants.CLASSIC && {
          color: 'blue',
          ':hover': {
            textDecoration: 'underline',
          },
        },
        inline && {
          display: 'inline',
        },
        color && {
          color,
        },
      ]}>
      <RouterLink
        style={{
          ...theme.typography.body3,
          color: 'inherit',
          textDecoration: 'inherit',
        }}
        to={to}
        {...props}>
        {children}
      </RouterLink>
    </Box>
  );
};
