import format from 'date-fns/format';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { AccessTypes } from '@/constants';
import { selectPlan, selectUserRegion } from '@/store';

export const useAccessInfo = () => {
  const isNasba = useSelector(selectUserRegion) === 'USA';
  const { data: plan } = useSelector(selectPlan);

  if (!plan || plan.planType === AccessTypes.NONE) return null;

  const { creditsRemaining, validUntil, freeTrial } = plan;
  const infoTitle = `${freeTrial ? 'Free Trial ' : ''}${isNasba ? 'Credits' : 'Time'} Remaining`;

  const formattedUsage = isNasba
    ? Math.round(creditsRemaining * 10) / 10
    : Math.round(creditsRemaining * 60);
  const usageUnits = `${isNasba ? 'credit' : 'minute'}${formattedUsage === 1 ? '' : 's'}`;

  return {
    showExpiry: !isNil(validUntil),
    showUsage: !isNil(creditsRemaining),
    showUpgradeBanner: freeTrial,
    infoTitle,
    usageRemaining: formattedUsage,
    usageUnits,
    expiryDate: format(new Date(validUntil), 'MMM d, yyyy'),
  };
};
