import { BorderColor } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { playerActions } from '@/store';
import { createRouteWithParams } from '@/utils';

export const QuizReadyTag = ({
  episodeId = null,
  chapterId = null,
  referrer = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <Box sx={{ '> button': { borderRadius: '20px' } }}>
      <Button
        startIcon={<BorderColor />}
        onClick={() => {
          dispatch(playerActions.setExpanded(false));

          const route = createRouteWithParams(Routes.EPISODE, { episodeId });
          if (location.pathname === route) return;

          navigate(route, {
            state: {
              referrer,
              activeQuizChapterId: chapterId,
            },
          });
        }}
        label="Quiz Ready!"
        variant="warning"
      />
    </Box>
  );
};
