import {
  Bookmark,
  Bookmarks,
  Pause,
  PlayArrow,
  Replay30,
  SkipNext,
  SkipPrevious,
} from '@mui/icons-material';
import { Box, CircularProgress, Hidden, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { ChapterStatus, Modals, PLAYER_REPLAY_30 } from '@/constants';
import { useBookmarkManagement, useDebouncedValue } from '@/hooks';
import { thumbsFeedback } from '@/images';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerActions,
  playerThunks,
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectChapterProgress,
  selectIsChangingChapter,
  selectIsFsmPlayer,
  selectIsPlayerBuffering,
  selectIsPlayerPlaying,
  selectIsPlayerSeeking,
  selectPlaybackSpeedOptons,
  selectPlayerSpeed,
  selectUserRegion,
  uiActions,
  useFetchChapterQuery,
  useFetchEpisodeChaptersQuery,
  useFetchEpisodeQuery,
  useFetchPlaybackSpeedOptionsQuery,
} from '@/store';

import { QuizReadyTag } from './components';

export const MediaControls = () => {
  const dispatch = useDispatch();

  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const activeChapterId = useSelector(selectActiveChapterId);
  const playerSpeed = useSelector(selectPlayerSpeed);
  const isBuffering = useSelector(selectIsPlayerBuffering);
  const isSeeking = useSelector(selectIsPlayerSeeking);
  const isPlaying = useSelector(selectIsPlayerPlaying);
  const isChangingChapter = useSelector(selectIsChangingChapter);
  const region = useSelector(selectUserRegion);
  const isFsmPlayer = useSelector(selectIsFsmPlayer);
  const playbackSpeedOptions = useSelector(selectPlaybackSpeedOptons);

  const isNasba = region === 'USA';

  useFetchPlaybackSpeedOptionsQuery(undefined, { skip: isFsmPlayer !== false });
  const { data: episode } = useFetchEpisodeQuery({
    episodeId: activeEpisodeId,
    region,
  });
  const { data: chapters } = useFetchEpisodeChaptersQuery({
    episodeId: activeEpisodeId,
    region,
  });
  const { data: chapter } = useFetchChapterQuery({
    chapterId: activeChapterId,
    region,
  });

  const chapterPosition = chapters?.find(
    ({ chapterId }) => chapterId === activeChapterId,
  )?.chapterPosition;
  const nextChapter = chapters?.find(
    current => current.chapterPosition === chapterPosition + 1,
  );
  const previousChapter = chapters?.find(
    current => current.chapterPosition === chapterPosition - 1,
  );
  const isChapterTrailer = activeChapterId === episode?.trailerId;

  // Selects the chapter data from the EntityAdapter as it gets continuously updated for e.g. set_position
  const chapterProgress = useSelector(state =>
    selectChapterProgress(state, {
      episodeId: activeEpisodeId,
      chapterId: activeChapterId,
    }),
  );

  const showLoadingIndicator = useDebouncedValue(
    isBuffering || isChangingChapter,
    1000,
  );

  const location = {
    component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER,
  };

  const { toggleBookmark, isBookmarking, isBookmarked } = useBookmarkManagement(
    {
      episodeId: episode?.episodeId,
      location: {
        ...location,
        button: EVENT_CONSTANTS.BUTTON.BOOKMARK,
      },
      referrer: location,
    },
  );

  const togglePlay = () => {
    dispatch(
      isPlaying ? playerThunks.pausePlayer() : playerThunks.playPlayer(),
    );

    if (chapter) {
      AnalyticsService.chapterPause(
        {
          chapter,
          episode,
          isTrailer: !!chapter.isTrailer,
          isVerifiable: !!chapter.isVerifiable,
          type: chapter.type || 'audio',
          location: {
            ...location,
            button: EVENT_CONSTANTS.BUTTON.PLAY,
          },
          referrer: location,
        },
        isPlaying,
      );

      AnalyticsService.trackPlayerPlayPause(
        {
          chapter,
          episode,
          isTrailer: !!chapter.isTrailer,
          isVerifiable: !!chapter.isVerifiable,
          type: chapter.type || 'audio',
          location: {
            ...location,
            button: EVENT_CONSTANTS.BUTTON.PLAY,
          },
          referrer: location,
        },
        isPlaying,
      );
    }
  };

  const playPrevious = () => {
    if (previousChapter) {
      AnalyticsService.trackPlayerSkip({
        chapter,
        episode,
        direction: EVENT_CONSTANTS.BUTTON.SKIP_PREVIOUS,
      });
      dispatch(
        playerThunks.chapterPlayPressed({
          activeChapter: {
            episodeId: activeEpisodeId,
            chapterId: previousChapter.chapterId,
          },
          location: {
            ...location,
            button: EVENT_CONSTANTS.BUTTON.SKIP_PREVIOUS,
          },
          referrer: location,
        }),
      );
    }
  };

  const playNext = () => {
    if (nextChapter) {
      AnalyticsService.trackPlayerSkip({
        chapter,
        episode,
        direction: EVENT_CONSTANTS.BUTTON.SKIP_NEXT,
      });
      dispatch(
        playerThunks.chapterPlayPressed({
          activeChapter: {
            episodeId: activeEpisodeId,
            chapterId: nextChapter.chapterId,
          },
          location: {
            ...location,
            button: EVENT_CONSTANTS.BUTTON.SKIP_NEXT,
          },
          referrer: location,
        }),
      );
    }
  };

  const setPlayerSpeedToNextOption = () => {
    const currentSpeedIndex = playbackSpeedOptions.indexOf(playerSpeed);
    const isCurrentSpeedLastOption =
      playbackSpeedOptions.length - 1 === currentSpeedIndex;
    const nextSpeedIndex = isCurrentSpeedLastOption ? 0 : currentSpeedIndex + 1;
    const nextSpeed = playbackSpeedOptions[nextSpeedIndex];

    AnalyticsService.playbackSpeedChanged({
      speed: nextSpeed,
      chapter,
      episode,
      location: {
        ...location,
        button: EVENT_CONSTANTS.BUTTON.SPEED,
      },
    });

    dispatch(playerActions.setPlaybackSpeed(nextSpeed));
  };

  const seekThirtySecondsBack = () => {
    AnalyticsService.trackPlayerRewind({
      chapter,
      episode,
      location: { ...location, button: EVENT_CONSTANTS.BUTTON.SEEK_THIRTY },
    });
    dispatch(playerThunks.seekToPosition({ delta: PLAYER_REPLAY_30 }));
  };

  return (
    <Box sx={styles.mediaControlsContainer}>
      {!isChapterTrailer && (
        <Hidden mdDown>
          <IconButton
            sx={styles.iconButton}
            onClick={toggleBookmark}
            disabled={isBookmarking}>
            <Box
              component={isFsmPlayer ? Bookmarks : Bookmark}
              sx={[styles.icon, isBookmarked && styles.activeIcon]}
            />
          </IconButton>
        </Hidden>
      )}
      <IconButton
        sx={styles.iconButton}
        onClick={seekThirtySecondsBack}
        disabled={isSeeking}>
        <Replay30 sx={styles.icon} />
      </IconButton>
      <Hidden smDown>
        <IconButton
          sx={styles.iconButton}
          onClick={playPrevious}
          disabled={isChapterTrailer || !previousChapter}>
          <SkipPrevious sx={styles.icon} />
        </IconButton>
      </Hidden>
      {showLoadingIndicator ? (
        <CircularProgress sx={styles.circularProgressIndicator} />
      ) : (
        <IconButton onClick={togglePlay}>
          {isPlaying ? (
            <Pause sx={[styles.playPauseIcon, styles.iconSelected]} />
          ) : (
            <PlayArrow sx={[styles.playPauseIcon, styles.iconSelected]} />
          )}
        </IconButton>
      )}
      <Hidden smDown>
        <IconButton
          sx={styles.iconButton}
          onClick={playNext}
          disabled={isChapterTrailer || !nextChapter}>
          <SkipNext sx={styles.icon} />
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <IconButton sx={styles.iconButton} onClick={setPlayerSpeedToNextOption}>
          <Box sx={styles.playbackSpeedIcon} component="span">
            {playerSpeed}x
          </Box>
        </IconButton>
      </Hidden>
      <IconButton
        sx={styles.iconButton}
        onClick={() => {
          dispatch(
            uiActions.setActiveModal({
              name: Modals.EPISODE_FEEDBACK,
              params: {
                episodeId: activeEpisodeId,
                chapterId: activeChapterId,
              },
            }),
          );

          AnalyticsService.trackPlayerFeedback({
            chapter,
            episode,
          });
        }}>
        <Box
          component="img"
          sx={styles.iconButton}
          src={thumbsFeedback}
          alt="Episode Feedback"
        />
      </IconButton>
      <Hidden mdDown>
        <Box ml={5}>
          {!isChapterTrailer &&
            !isNasba &&
            chapterProgress?.status === ChapterStatus.QUIZ_READY && (
              <QuizReadyTag
                episodeId={activeEpisodeId}
                chapterId={activeChapterId}
                referrer={{
                  page: 'player',
                  component: EVENT_CONSTANTS.COMPONENT.MINI_PLAYER,
                }}
              />
            )}
        </Box>
      </Hidden>
    </Box>
  );
};

const styles = {
  mediaControlsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    margin: '0 auto',
    paddingTop: theme => theme.typography.pxToRem(4),
  },
  iconButton: {
    height: 56,
    width: 56,
  },
  playPauseIcon: theme => ({
    fontSize: theme.typography.pxToRem(60),
    color: 'blueBlackLight',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  }),
  icon: {
    fontSize: theme => theme.typography.pxToRem(30),
    color: 'blueBlackLight',
  },
  activeIcon: {
    color: 'tealBright',
  },
  playbackSpeedIcon: {
    fontSize: theme => theme.typography.pxToRem(20),
    color: 'blueBlackLight',
  },
  iconSelected: {
    color: 'aqua',
  },
  playEpisodeButton: {
    color: 'aqua',
    fontSize: theme => theme.typography.pxToRem(18),
  },
  circularProgressIndicator: {
    color: 'aqua',
    fontSize: theme => theme.typography.pxToRem(32),
  },
};
