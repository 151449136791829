import { Typography } from '@mui/material';

export const ModalTitle = ({ variant = 'dark', children }) => (
  <Typography sx={[styles[variant]]} variant="h2">
    {children}
  </Typography>
);

const styles = {
  dark: { color: 'text.black' },
  light: { color: 'text.white' },
};
