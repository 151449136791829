import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import config from '@/config';
import { Routes } from '@/constants';

import { useInitHook } from './useInitHook';

export const Intercom = () => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const hideIntercom = isMobile || pathname === Routes.CPA_CANADA;

  useInitHook(({ userId, userProfile }) => {
    window.Intercom('boot', {
      user_id: userId,
      email: userProfile?.email,
      app_id: config.INTERCOM_APP_ID,
      vertical_padding: 100,
      hide_default_launcher: hideIntercom,
    });
  });

  return null;
};
