import { GraphicEq } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Modals } from '@/constants';
import { playerThunks, uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const BrowserAutoPlayModal = () => {
  const dispatch = useDispatch();

  const handleClickClose = () => {
    dispatch(uiActions.closeActiveModal());
    dispatch(playerThunks.playPlayer());
  };

  return (
    <AppModal modalName={Modals.BROWSER_AUTOPLAY_PROHIBITED}>
      <Paper sx={styles.paper}>
        <Box>
          <GraphicEq sx={styles.icon} />
        </Box>
        <Box>
          <Typography sx={styles.title}>Autoplay Media</Typography>

          <Typography sx={styles.message}>
            Your browser settings require us to ask for permission before
            playing this media.
          </Typography>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button
            variant="contained"
            sx={styles.button}
            colour="tealLight"
            onClick={handleClickClose}>
            Allow
          </Button>
        </Box>
      </Paper>
    </AppModal>
  );
};

const styles = {
  paper: theme => ({
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.typography.pxToRem(50)} ${theme.typography.pxToRem(30)}`,
    textAlign: 'center',
    width: theme.typography.pxToRem(600),
    [theme.breakpoints.down('sm')]: {
      padding: theme.typography.pxToRem(40),
      width: 'auto',
    },
  }),
  icon: theme => ({
    color: 'teal',
    fontSize: theme.typography.pxToRem(100),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(80),
    },
  }),
  title: theme => ({
    ...theme.typography.h1,
    color: 'blueBlack',
    mb: 1,
  }),
  message: theme => ({
    ...theme.subtitle1,
    color: 'blueBlack',
    textAlign: 'center',
  }),
  buttonContainer: theme => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    mt: 3,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      marginTop: theme.typography.pxToRem(9),
    },
  }),
  button: theme => ({
    ...theme.h6,
    height: theme.typography.pxToRem(50),
    minWidth: theme.typography.pxToRem(200),
    p: 1,
  }),
};
