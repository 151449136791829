const config = {
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN as string,
  SENTRY_PROXY: import.meta.env.VITE_SENTRY_PROXY_URL as string,
  ALGOLIA_APP_ID: import.meta.env.VITE_ALGOLIA_APP_ID as string,
  ALGOLIA_API_KEY: import.meta.env.VITE_ALGOLIA_API_KEY as string,
  APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN as string,
  INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
  LOGROCKET_PROJECT_KEY: import.meta.env.VITE_LOGROCKET_PROJECT_KEY,
  STRIPE_API_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  MIX_PANEL_PROJECT_TOKEN: import.meta.env.VITE_MIX_PANEL_PROJECT_TOKEN,
  GROWTHBOOK_CLIENT_CODE: import.meta.env.VITE_GROWTHBOOK_CLIENT_CODE,
};

export default config;
