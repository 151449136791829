import { Feedback } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { AppButton } from '@/components';
import { EpisodeStatus, Modals } from '@/constants';
import { selectUserRegion, uiActions, userEpisodesSelectors } from '@/store';

// TODO: hide this button if eval already submitted? or maybe let people update the feedback record?
export const EpisodeEvaluation = ({ episodeId }) => {
  const dispatch = useDispatch();

  const region = useSelector(selectUserRegion);
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );

  if (region === 'CAN' || userEpisode?.status !== EpisodeStatus.VERIFIED)
    return null;

  return (
    <Box
      sx={theme => ({
        mt: 2,
        p: 2.5,
        borderRadius: 1,
        border: `1px solid ${theme.palette.blueBlack}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      })}>
      <Typography variant="h3" sx={{ mr: 2 }}>
        Episode Evaluation
      </Typography>
      <AppButton
        onClick={() => {
          dispatch(
            uiActions.setActiveModal({
              name: Modals.EPISODE_EVALUATION,
              params: { episodeId },
            }),
          );
        }}>
        <Feedback sx={{ mr: 1 }} />
        <Typography variant="button2">Provide Feedback</Typography>
      </AppButton>
    </Box>
  );
};
