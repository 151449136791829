import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SwpieableViews from 'react-swipeable-views';

import { Skeleton } from '@/components';
import { ChapterStatus, Routes } from '@/constants';
import { AnalyticsService } from '@/services';
import {
  selectChapterProgress,
  selectIsFsmPlayer,
  selectUserRegion,
  userEpisodesSelectors,
  useSubmitQuizLegacyMutation,
} from '@/store';
import { useAnswerQuizMutation } from '@/store/player-fsm-api';
import { convertSecondsToMinutes, SnackbarUtils } from '@/utils';

export const ChapterQuiz = ({
  chapter,
  quizzes,
  onQuizSuccess,
  chapterIndex,
  maxChapterIndex,
  onSelectChapter,
}) => {
  const navigate = useNavigate();
  const region = useSelector(selectUserRegion);
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, chapter.episodeId),
  );
  const isFsmPlayerUser = useSelector(selectIsFsmPlayer);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isError, setIsError] = useState(false);
  const isTestMode = localStorage.getItem('testMode') === 'true';

  const userChapter = useSelector(state =>
    selectChapterProgress(state, {
      episodeId: chapter.episodeId,
      chapterId: chapter.chapterId,
    }),
  );

  const [submitQuizLegacy, { isLoading: isSubmitLoadingLegacy }] =
    useSubmitQuizLegacyMutation();
  const [submitQuizFsm, { isLoading: isSubmitLoadingFsm }] =
    useAnswerQuizMutation();

  const isDisabled = isCorrect || !userChapter?.completed;
  const isQuizCompleted = isFsmPlayerUser
    ? userChapter?.status === ChapterStatus.VERIFIED
    : quizzes[currentQuizIndex]?.completed;

  useEffect(() => {
    setSelectedAnswer(null);
    setIsCorrect(false);
    setIsError(false);
  }, [currentQuizIndex]);

  useEffect(() => {
    setCurrentQuizIndex(0);
    setSelectedAnswer(null);
    setIsCorrect(false);
    setIsError(false);
  }, [chapter]);

  useEffect(() => {
    if (isQuizCompleted) {
      setSelectedAnswer(
        quizzes[currentQuizIndex].answers.find(a => a.isCorrect)?.id - 1,
      );
      setIsCorrect(true);
    }
  }, [quizzes, currentQuizIndex, isQuizCompleted]);

  const onPreviousQuiz = () => {
    if (currentQuizIndex === 0) {
      onSelectChapter?.(chapterIndex - 1);
    } else {
      setCurrentQuizIndex(Math.max(currentQuizIndex - 1, 0));
    }
  };

  const onNextQuiz = () => {
    if (currentQuizIndex === quizzes.length - 1) {
      onSelectChapter?.(chapterIndex + 1);
    } else {
      setCurrentQuizIndex(Math.min(currentQuizIndex + 1, quizzes.length - 1));
    }
  };

  const onSubmitQuizLegacy = async () => {
    const payload = {
      episodeId: chapter.episodeId,
      quizId: quizzes[currentQuizIndex].quizId,
      answerId: quizzes[currentQuizIndex].answers[selectedAnswer].id,
      region,
    };
    const { data: pass } = await submitQuizLegacy(payload);

    if (pass) {
      SnackbarUtils.success(
        region === 'USA'
          ? 'Awesome, you got it right!'
          : `Well done! You've earned ${convertSecondsToMinutes(chapter?.duration ?? 0)} minutes of CPD!`,
      );
    } else {
      SnackbarUtils.warning('Sorry, wrong answer please try again!');
    }

    AnalyticsService.quizAttempt({
      chapterId: chapter.chapterId,
      chapterName: chapter.name,
      quizId: payload.quizId,
      answer: payload.answerId,
      pass,
    });
  };

  const onSubmitQuizFsm = async () => {
    const payloadLegacy = {
      episodeId: chapter.episodeId,
      quizId: quizzes[currentQuizIndex].quizId,
      answerId: quizzes[currentQuizIndex].answers[selectedAnswer].id,
      region,
    };
    // validate via old endpoint for now
    const submitQuizLegacyResponse = await submitQuizLegacy(payloadLegacy);
    if ('error' in submitQuizLegacyResponse) {
      SnackbarUtils.warning(
        'Failed to submit quiz, please try again or contact support.',
      );
      return;
    }
    const { data: isAnswerCorrect } = submitQuizLegacyResponse;

    if (!isAnswerCorrect) {
      SnackbarUtils.warning('Sorry, wrong answer please try again!');
      return;
    }

    const payload = {
      sessionId: userEpisode.sessionId,
      chapterId: chapter.chapterId,
      // answers: quizzes[currentQuizIndex].answers[selectedAnswer].id,
    };

    const { error } = await submitQuizFsm(payload);
    if (error) {
      SnackbarUtils.warning(
        'Failed to submit quiz, please try again or contact support.',
      );
      return;
    }

    onQuizSuccess(quizzes[currentQuizIndex].quizId);

    SnackbarUtils.success(
      region === 'USA'
        ? 'Awesome, you got it right!'
        : `Well done! You've earned ${convertSecondsToMinutes(chapter?.duration ?? 0)} minutes of CPD!`,
    );

    AnalyticsService.quizAttempt({
      chapterId: chapter.chapterId,
      chapterName: chapter.name,
      quizId: payload.quizId,
      answer: payload.answerId,
      pass: isAnswerCorrect,
    });
  };

  const onSubmitQuiz = isFsmPlayerUser ? onSubmitQuizFsm : onSubmitQuizLegacy;
  const isSubmitLoading = isFsmPlayerUser
    ? isSubmitLoadingFsm
    : isSubmitLoadingLegacy;

  return (
    chapter &&
    quizzes && (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            flexWrap: 'wrap',
          }}>
          <Typography variant="h1" sx={{ fontWeight: 600 }}>
            {quizzes.length === 1
              ? 'Quiz'
              : `Quizzes (${currentQuizIndex + 1}/${quizzes.length})`}
          </Typography>
          {!userChapter?.completed && (
            <Typography variant="h4" sx={{ fontWeight: 400 }}>
              &nbsp;| Available when chapter is completed
            </Typography>
          )}
        </Box>
        <SwpieableViews index={currentQuizIndex} enableMouseEvents={false}>
          {quizzes.map(quiz => (
            <Box key={quiz.quizId} mr={0.5} mt={3}>
              {userChapter?.completed ? (
                <Typography variant="body1">{quiz.question}</Typography>
              ) : (
                <Box>
                  <Skeleton animation={false} />
                  <Skeleton animation={false} />
                  <Skeleton animation={false} />
                </Box>
              )}
              {quiz.answers.map((answer, index) => (
                <Box key={index}>
                  <Box
                    onClick={() => {
                      if (!isDisabled) setSelectedAnswer(index);
                    }}
                    sx={[
                      styles.answerContainer,
                      isTestMode &&
                        answer.isCorrect &&
                        userChapter?.completed &&
                        styles.answerCorrect,
                      !isDisabled && styles.answerClickable,
                      selectedAnswer === index && styles.answerSelected,
                    ]}>
                    {userChapter?.completed ? (
                      <Typography variant="body1">
                        {(index + 10).toString(36).toUpperCase()}){' '}
                        {answer.answer}
                      </Typography>
                    ) : (
                      <Skeleton animation={false} />
                    )}
                  </Box>
                  {(isCorrect || isError) && region === 'USA' && (
                    <Box
                      sx={[
                        styles.answerCaption,
                        answer.isCorrect && styles.answerCaptionCorrect,
                      ]}>
                      <Typography variant="body1">
                        <b>
                          {answer.isCorrect ? 'Correct' : 'Incorrect'}:&nbsp;
                        </b>
                        {answer.caption}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          ))}
        </SwpieableViews>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: { sm: 'initial', xs: 'wrap' },
            mt: 2,
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: { sm: 'initial', xs: '100%' },
            }}>
            <Button
              sx={[
                styles.button,
                { justifyContent: { sm: 'initial', xs: 'flex-start' } },
              ]}
              disabled={chapterIndex === 0 && currentQuizIndex === 0}
              onClick={onPreviousQuiz}>
              <ArrowBack />
              <Typography variant="subtitle2">Previous</Typography>
            </Button>
            <Button
              sx={[
                styles.button,
                { justifyContent: { sm: 'initial', xs: 'flex-end' } },
              ]}
              disabled={
                currentQuizIndex === quizzes.length - 1 &&
                chapterIndex === maxChapterIndex
              }
              onClick={onNextQuiz}>
              <Typography variant="subtitle2">Next</Typography>
              <ArrowForward />
            </Button>
          </Box>
          {isQuizCompleted && (
            <Button
              onClick={() => navigate(Routes.TRACKER)}
              sx={[
                styles.button,
                styles.summaryButton,
                {
                  backgroundImage: theme => theme.gradients.success,
                  color: 'white',
                  fontWeight: 700,
                },
              ]}>
              <Typography variant="subtitle2">View Certificate</Typography>
            </Button>
          )}
          {!isQuizCompleted && (
            <Button
              disabled={R.isNil(selectedAnswer) || isSubmitLoading}
              sx={[
                styles.button,
                styles.summaryButton,
                {
                  backgroundImage: theme => theme.gradients.primary,
                  color: 'white',
                  fontWeight: 700,
                  '&:disabled': {
                    color: 'gray',
                    backgroundColor: 'grayBrightLight',
                    backgroundImage: 'none',
                  },
                },
              ]}
              onClick={onSubmitQuiz}>
              <Typography variant="subtitle2">Submit</Typography>
            </Button>
          )}
        </Box>
      </Box>
    )
  );
};

const styles = {
  answerContainer: theme => ({
    border: theme => `1px solid ${theme.palette.blueBlack}`,
    borderRadius: 1,
    p: 2.5,
    mt: 1.5,
    minHeight: 70,
    backgroundColor: 'white',
    color: 'gray',
  }),
  answerClickable: {
    cursor: 'pointer',
  },
  answerSelected: {
    backgroundColor: 'blue',
    color: 'white',
  },
  answerCorrect: {
    backgroundColor: 'greenLight',
  },
  answerCaption: {
    mt: 1,
    color: 'red',
    fontStyle: 'italic',
  },
  answerCaptionCorrect: {
    color: 'green',
  },
  button: {
    width: 120,
    height: 50,
    mr: {
      sm: 1,
    },
    p: {
      sm: 1,
      xs: 0,
    },
    color: 'blueBlack',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryButton: {
    width: 150,
    mt: { sm: 0, xs: 1 },
  },
};
