import {
  AccountCircle,
  Email,
  Logout,
  ManageAccounts,
} from '@mui/icons-material';
import { Box, Button, Hidden, IconButton, Menu } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Routes } from '@/constants';
import { uiActions } from '@/store';

export const ProfileMenu = ({ logout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = event => {
    event.stopPropagation();
    dispatch(uiActions.closeSidebarDrawer());
    setIsOpen(true);
  };

  const handleClose = event => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <Hidden mdDown>
      <IconButton onClick={handleClick}>
        <AccountCircle sx={styles.appIcon} ref={menuRef} />
      </IconButton>
      <Menu
        anchorEl={menuRef.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        styles={{
          paper: {
            border: theme => `1px solid ${theme.palatte.grayMid}`,
          },
        }}>
        <Box sx={styles.menuContainer}>
          <Button
            onClick={() => {
              navigate(Routes.PROFILE);
              setIsOpen(false);
            }}>
            <ManageAccounts />
            <span>Profile</span>
          </Button>
          <Button
            onClick={() => {
              window.Intercom?.('show');
              setIsOpen(false);
            }}>
            <Email />
            <span>Support</span>
          </Button>
          <Button onClick={logout}>
            <Logout />
            <span>Logout</span>
          </Button>
        </Box>
      </Menu>
    </Hidden>
  );
};

const styles = {
  appIcon: {
    color: 'white',
    width: '2rem',
    height: '2rem',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '20rem',
    p: 1,
    '> *': {
      display: 'flex',
      gap: 1.5,
      justifyContent: 'normal',
    },
  },
};
