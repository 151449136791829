import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

export const sessionConfig = {
  timeout: 1800000,

  getSessionId() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  },

  setSessionId() {
    mixpanel.register({
      session_id: sessionConfig.getSessionId(),
    });
  },

  checkSessionId() {
    if (!mixpanel.get_property('last_event_time')) {
      sessionConfig.setSessionId();
    }

    if (!mixpanel.get_property('session_id')) {
      sessionConfig.setSessionId();
    }

    if (
      Date.now() - mixpanel.get_property('last_event_time') >
      sessionConfig.timeout
    ) {
      sessionConfig.setSessionId();
    }
  },
};

// makes sure all our event params are valid strings and never null or undefined
export function validateParamValue(value) {
  if (_.isObject(value)) {
    if (_.isEmpty(value)) {
      return '';
    }
    const obj = {};
    Object.keys(value).forEach(key => {
      obj[key] = validateParamValue(value[key]);
    });
    return obj;
  }

  return value;
}
