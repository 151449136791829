import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';

import { urls } from '@/constants';
import { AnalyticsService, CPA_BUTTON_NAME } from '@/services';
import { selectSessionUserId } from '@/store';

export const CPAHeader = ({ cpaCanadaContent }) => {
  const isAnonymous = !useSelector(selectSessionUserId);
  const headerButtonText = isAnonymous ? 'Claim 5 Free Hours' : 'Back to LumiQ';
  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftContainer}>
        <Box
          component="img"
          alt=""
          src={cpaCanadaContent?.header.logoCpa}
          sx={styles.cpacanadalogo}
          onClick={() => {
            AnalyticsService.buttonClick(CPA_BUTTON_NAME.CANADA_LOGO);
            window.open('/', '_self');
          }}
        />
      </Box>

      <Box sx={styles.rightContainer}>
        <Button
          sx={styles.signUpButton}
          variant="contained"
          onClick={() => {
            AnalyticsService.buttonClick(
              isAnonymous
                ? CPA_BUTTON_NAME.SIGN_UP
                : CPA_BUTTON_NAME.BACK_TO_LUMIQ,
            );

            const url = isAnonymous
              ? urls.accounts.root + cpaCanadaContent.header.signupUrl
              : '/';
            window.open(url, '_self');
          }}>
          {headerButtonText}
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: theme => ({
    mt: 4,
    mb: 4,
    height: theme.typography.pxToRem(theme.dimensions.header.height),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      height: theme.typography.pxToRem(90),
      ml: 4,
      mr: 4,
    },
  }),
  signUpButton: theme => ({
    ...theme.typography.h2,
    alignSelf: 'center',
    textTransform: 'none',
    color: 'white',
    width: 'fit-content',
    height: theme.typography.pxToRem(55),
    minWidth: theme.typography.pxToRem(160),
    backgroundColor: 'bluePrimary',
    borderRadius: theme.typography.pxToRem(30),
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      minWidth: theme.typography.pxToRem(100),
      fontSize: theme.typography.pxToRem(18),
      height: theme.typography.pxToRem(45),
      mt: 5,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  }),
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  rightContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
  cpacanadalogo: theme => ({
    height: theme.typography.pxToRem(70),
    width: theme.typography.pxToRem(323),
    [theme.breakpoints.down('xs')]: {
      width: theme.typography.pxToRem(205),
      height: theme.typography.pxToRem(47),
    },
  }),
};
