import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Button } from '@/components';
import { Modals } from '@/constants';
import { ethicsLocked } from '@/images';
import { uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const EthicsLockedModal = () => {
  const dispatch = useDispatch();
  return (
    <AppModal modalName={Modals.ETHICS_LOCKED}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="h1">
          Ethics Content Locked In Free Trial
        </Typography>
        <img src={ethicsLocked} alt="Ethics Locked" style={{ maxWidth: 174 }} />
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          All ethics content is locked in our free trial, and is only available
          to premium users. Please reach out to our team to learn how to get
          access to a premium account.
        </Typography>
        <Box
          sx={theme => ({
            mt: 3,
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: 2,
            },
          })}>
          <Box width={140}>
            <Button
              variant="secondary"
              label={'Close'}
              onClick={() => dispatch(uiActions.closeActiveModal())}
            />
          </Box>
          <Box width={140}>
            <Button
              variant="primary"
              label={'Contact Us'}
              onClick={() => window.Intercom?.('show')}
            />
          </Box>
        </Box>
      </Box>
    </AppModal>
  );
};
