import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { assessmentFail, assessmentPass } from '@/images';
import { AnalyticsService, EVENTS } from '@/services';
import {
  selectActiveModalParams,
  uiActions,
  useFetchEpisodeQuery,
} from '@/store';
import { useFetchEpisodeAssessmentQuery } from '@/store/assessments-api';
import { useSubmitAssessmentMutation } from '@/store/user-assessments-api';

import { AssessmentQuestionSkeleton } from './AssessmentQuestionSkeleton';

export const AssessmentModalContent = () => {
  const dispatch = useDispatch();
  const { episodeId } = useSelector(selectActiveModalParams) || {};
  const isTestMode = localStorage.getItem('testMode') === 'true';
  const { data: assessment, isLoading: isAssessmentLoading } =
    useFetchEpisodeAssessmentQuery({ episodeId }, { skip: !episodeId });
  const { data: episode } = useFetchEpisodeQuery(
    {
      episodeId,
      region: 'USA',
    },
    { skip: !episodeId },
  );
  const [answers, setAnswers] = useState([]);
  const [
    submitAssessment,
    { data: submitAssessmentResponse, reset, isLoading: isSubmitLoading },
  ] = useSubmitAssessmentMutation();

  useEffect(() => {
    if (assessment && assessment.questions?.length > 0) {
      setAnswers(assessment.questions.map(question => null));
    }
  }, [assessment]);

  if (submitAssessmentResponse) {
    return (
      <DialogContent
        sx={{
          width: 400,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        {submitAssessmentResponse.pass && (
          <>
            <Typography variant="h1" sx={{ fontWeight: 600 }}>
              Well Done!
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              You passed with a score of {submitAssessmentResponse?.score}%
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              You have earned {episode.CPEDetails.credits} credit
              {episode.CPEDetails.credits !== 1 && 's'}
            </Typography>
            <Box mt={2}>
              <img src={assessmentPass} alt="pass" style={{ maxWidth: 150 }} />
            </Box>
            {episode.isEthics && (
              <>
                <Typography variant="subtitle1" mt={2}>
                  This episode has ethics qualified chapters:
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  You obtained {episode.CPEDetails.credits} ethics credit
                </Typography>
              </>
            )}
            <Box mt={2}>
              <Button
                variant="secondary"
                label="Close"
                fullWidth={false}
                onClick={() => {
                  dispatch(uiActions.closeActiveModal());
                  reset();
                }}
              />
            </Box>
          </>
        )}
        {!submitAssessmentResponse.pass && (
          <>
            <Typography variant="h1" sx={{ fontWeight: 600 }}>
              Let&apos;s try one more time
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              You need at least 70% to pass
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              and you got only got {submitAssessmentResponse?.score}%
            </Typography>
            <Box mt={2}>
              <img src={assessmentFail} alt="fail" style={{ maxWidth: 240 }} />
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2, color: 'gray' }}>
              We know these exams can be hard sometimes, that's why we have
              unlimited tries!
            </Typography>
            <Box mt={2}>
              <Button
                variant="primary"
                label="Try Again"
                fullWidth={false}
                onClick={() => {
                  reset();
                  setAnswers(assessment.questions.map(question => null));
                }}
              />
            </Box>
            <Box mt={2}>
              <Button
                variant="secondary"
                label="Close"
                fullWidth={false}
                onClick={() => {
                  dispatch(uiActions.closeActiveModal());
                  reset();
                }}
              />
            </Box>
          </>
        )}
      </DialogContent>
    );
  }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h3">Qualified Assessment</Typography>
      </DialogTitle>
      <DialogContent
        sx={theme => ({
          maxWidth: { sm: theme.breakpoints.values.md },
        })}>
        <Typography variant="body1" sx={{ mt: 3, mb: 3, fontWeight: 'bold' }}>
          In accordance with NASBA standards, participants are required to earn
          a minimum score of 70% in order to pass the assessment.
        </Typography>
        {isAssessmentLoading ? (
          Array.from({ length: 4 }).map((_, idx) => (
            <Box key={idx} mb={2}>
              <AssessmentQuestionSkeleton />
            </Box>
          ))
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {assessment.questions.map((question, index) => (
              <FormControl key={question.id} sx={{ mt: 2 }}>
                <FormLabel>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: 'gray', mb: 1 }}>
                    {index + 1}.&nbsp;&nbsp;&nbsp;{question.question}
                  </Typography>
                </FormLabel>
                <RadioGroup>
                  {question.answers.map(answer => (
                    <FormControlLabel
                      key={answer.id}
                      value={answer.id}
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: 'bluePrimary',
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            color:
                              isTestMode && answer.isCorrect
                                ? 'green'
                                : 'blueBlack',
                          }}>
                          {answer.answer}
                        </Typography>
                      }
                      onChange={e => {
                        setAnswers(R.update(index, e.target.value, answers));
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          label="Close"
          fullWidth={false}
          onClick={() => {
            dispatch(uiActions.closeActiveModal());

            AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.CLOSE, {
              episodeId,
              assessmentId: assessment.assessmentId,
              episodeName: episode?.name,
            });
          }}
        />
        <Button
          variant="primary"
          label="Submit"
          fullWidth={false}
          disabled={answers.some(res => R.isNil(res)) || isSubmitLoading}
          onClick={async () => {
            const { data: result } = await submitAssessment({
              assessmentId: assessment.assessmentId,
              answers,
            });

            AnalyticsService.assessmentAttempt({
              episodeId,
              assessmentId: assessment.assessmentId,
              episodeName: episode?.name,
              answer: answers,
              score: result?.score,
              pass: result?.pass,
            });

            if (result?.pass) {
              AnalyticsService.episodeComplete({
                episode,
                isNasba: true,
              });
            }
          }}
        />
      </DialogActions>
    </>
  );
};
