import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import {
  AccessTypes,
  LUMIQ_ORGANIZATION_NAME,
  LUMIQ_TEST_ORGANIZATION_NAME,
} from '@/constants';
import { selectAppRegion, selectSessionUserId } from '@/store';

import { RootState } from '../store';
import { userApiNode, userApiPostgrest } from './user-api.slice';

export const selectUserProfileResult =
  userApiPostgrest.endpoints.fetchUserProfile.select(undefined);

export const selectUserRegionResult =
  userApiNode.endpoints.fetchUserRegion.select(undefined);

export const selectUserRegion = (state: RootState) => {
  const { isLoading, data } = selectUserRegionResult(state);
  if (isLoading) return undefined;
  return data || selectAppRegion(state);
};

export const selectIsFreeTrialUser = (state: RootState) =>
  selectPlan(state)?.data?.freeTrial;

export const getDisablePurchasing = (state: RootState) => {
  const userId = selectSessionUserId(state);
  if (!userId) return false;

  const { data: plan } = selectPlan(state);
  if (!plan) return false;

  const isB2CUser = selectIsB2CUser(state);

  /* Disable purchasing if user has one of:
  - valid time plan
  - valid org usage plan
  */
  return (
    (plan.planType === AccessTypes.TIME_PLAN &&
      new Date(plan.validUntil) > new Date()) ||
    (plan.planType === AccessTypes.USAGE_PLAN &&
      (R.isNil(plan.creditsRemaining) || plan.creditsRemaining > 0) &&
      !isB2CUser)
  );
};

export const selectPlan =
  userApiPostgrest.endpoints.fetchPlan.select(undefined);

export const selectIsB2CUser = createSelector(selectPlan, ({ data: plan }) => {
  return getIsB2CUser(plan);
});

export const getIsB2CUser = (plan?: { organizationName: string }) =>
  !plan ||
  plan?.organizationName === LUMIQ_ORGANIZATION_NAME ||
  plan?.organizationName === LUMIQ_TEST_ORGANIZATION_NAME;
