import { Box, Slide } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  playerActions,
  playerThunks,
  selectActiveChapterId,
  selectIsPlayerExpanded,
  selectUserRegion,
  useFetchChapterQuery,
} from '@/store';

import { AudioVideoPlayer, MiniPlayer, VideoPlayer } from './components';

export const Player = () => {
  const dispatch = useDispatch();
  const activeChapterId = useSelector(selectActiveChapterId);
  const isExpanded = useSelector(selectIsPlayerExpanded);
  const region = useSelector(selectUserRegion);
  const { isVideo } = useFetchChapterQuery(
    {
      chapterId: activeChapterId,
      region,
    },
    {
      selectFromResult: result => ({
        isVideo: result.data?.type === 'video',
      }),
    },
  );

  const player = <AudioVideoPlayer />;

  useEffect(() => {
    dispatch(playerThunks.resumeLastSession());

    return () => dispatch(playerActions.clearActiveChapter());
  }, [dispatch]);

  if (!activeChapterId) return null;

  return (
    <>
      <Box sx={[styles.backdrop, isExpanded && styles.backdropExpanded]} />
      <Box sx={styles.container}>
        {isVideo ? (
          <Slide direction="up" in={isExpanded}>
            <Box
              sx={[
                styles.playerContainer,
                isExpanded && styles.playerContainerExpanded,
              ]}>
              <VideoPlayer player={player} />
            </Box>
          </Slide>
        ) : (
          <Box sx={styles.audioPlayer}>{player}</Box>
        )}
        <MiniPlayer />
      </Box>
    </>
  );
};

const styles = {
  backdrop: {
    backgroundColor: 'backdrop',
    display: 'none',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 'player',
  },
  backdropExpanded: {
    display: 'block',
  },
  container: {
    bottom: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 'player',
  },
  audioPlayer: {
    height: 0,
    width: 0,
  },
  playerContainer: theme => ({
    backgroundColor: 'blueBlack',
    borderTopLeftRadius: theme.typography.pxToRem(32),
    borderTopRightRadius: theme.typography.pxToRem(32),
    bottom: 0,
    boxShadow: '0 -5px 20px 0 rgba(0,0,0,0.5)',
    height: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
    zIndex: -1, // render behind the parent container that is placed at index 1300
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }),
  playerContainerExpanded: theme => ({
    height: 'auto',
    padding: `${theme.typography.pxToRem(50)} ${theme.typography.pxToRem(40)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(10)}`,
    },
  }),
};
