import * as R from 'ramda';

import { COUNTRIES } from '@/constants';

export const selectDisclaimerDesignationOptions = options =>
  options?.filter(i => i.disclaimer) || [];

export const selectNonDesignatedCountryOptions = options => {
  if (!options) return [];

  const countryCodes = R.pipe(
    R.filter(R.propEq(false, 'designated')),
    R.map(R.prop('countryCode')),
    R.uniq,
  )(options);

  return R.pipe(R.indexBy(R.prop('code3')), R.pick(countryCodes))(COUNTRIES);
};

export const selectCountryOptions = options => {
  if (!options) return [];

  const countryCodes = R.pipe(R.map(R.prop('countryCode')), R.uniq)(options);

  return R.pipe(R.indexBy(R.prop('code3')), R.pick(countryCodes))(COUNTRIES);
};

export const selectDesignationTypeOptions = options => {
  if (!options) return null;

  return R.pipe(
    R.filter(R.propEq(true, 'designated')),
    R.groupBy(R.prop('countryCode')),
    R.map(
      R.reduce(
        (accumulator, option) => ({
          ...accumulator,
          [option.designation]: option.description,
        }),
        {},
      ),
    ),
  )(options);
};
