import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Routes } from '@/constants';
import { lumiqWhiteLogoBig } from '@/images';
import { uiActions } from '@/store';

export const NavbarLogo = () => {
  const dispatch = useDispatch();

  return (
    <Box component={Link} to={Routes.DISCOVER} sx={styles.logoContainer}>
      <Box
        component="img"
        src={lumiqWhiteLogoBig}
        sx={styles.lumiqLogo}
        onClick={() => dispatch(uiActions.closeSidebarDrawer())}
        alt="lumiq logo"
      />
    </Box>
  );
};

const styles = {
  logoContainer: theme => ({
    display: 'flex',
    alignItems: 'center',
    ml: 2.5,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      ml: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
  lumiqLogo: {
    width: theme => theme.typography.pxToRem(96),
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
