import { Box, TextField } from '@mui/material';
import { useState } from 'react';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { HighlightedText } from './HighlightedText';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const EmailForm = ({ goToPrevStep, goToNextStep }) => {
  const [form, setForm] = useState('');
  const [error, setError] = useState();

  const handleChange = event => {
    const { value } = event.target;
    setForm(value);

    if (error && isFormValid(value)) setError();
  };

  const handleNext = () => {
    if (isFormValid(form)) {
      goToNextStep(form);
    } else {
      setError('Please enter a valid email address.');
    }
  };

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box sx={styles.formPrimarySection}>
        <TextField
          id={'email'}
          value={form}
          onChange={handleChange}
          label="Email"
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error}
        />
      </Box>
      <Footer handlePrev={() => goToPrevStep()} handleNext={handleNext} />
    </Box>
  );
};

const isFormValid = form => {
  if (!form) return true;

  const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegexPattern.test(form);
};

const Title = () => (
  <ModalTitle>Has your company or firm purchased LumiQ for you?</ModalTitle>
);

const Subtitle = () => {
  return (
    <ModalSubtitle>
      You currently have access to a{' '}
      <HighlightedText>free trial</HighlightedText>. If you are expecting to
      login to a corporate plan, please enter your work email below to receive
      an invitation.
    </ModalSubtitle>
  );
};
