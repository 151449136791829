import { Box } from '@mui/material';

import { lumiqBlueGradientLogoWatermark } from '@/images';

export const Header = ({ Title, Subtitle, logo }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pb: 2,
        pt: { xs: 2, sm: 6 },
        zIndex: 1,
      }}>
      <Box>
        <Box>{Title}</Box>
        <Box mt={3}>{Subtitle}</Box>
      </Box>
      <Box ml={2}>
        <img
          src={logo || lumiqBlueGradientLogoWatermark}
          style={{ height: 48, width: 48 }}
          alt="lumiq logo"
        />
      </Box>
    </Box>
  );
};
