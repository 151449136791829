import { Box, CircularProgress } from '@mui/material';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AnalyticsService, EVENTS, logBreadcrumb } from '@/services';
import {
  getIsB2CUser,
  selectDisclaimerDesignationOptions,
  uiActions,
  useCompleteOnboardingMutation,
  useFetchDesignationOptionsQuery,
  useFetchPlanQuery,
  useSendAccountLinkingEmailMutation,
  useUpdateUserDesignationsMutation,
  useUpdateUserProfileMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { MODAL_HEIGHT } from './styles';
import {
  getDesignationCountry,
  getDisclaimerText,
  getNextStepId,
  getPrevStepId,
  getSteps,
  STEP_IDS,
  STEPS,
} from './utils';

export const OnboardingFormStepsContext = createContext();

export const OnboardingForm = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    userCountry: null,
    designations: null,
    name: null,
    email: null,
  });
  const [activeStep, setActiveStep] = useState(STEPS[0].id);
  const [goToNextStep, setGoToNextStep] = useState(false);
  const [goToPrevStep, setGoToPrevStep] = useState(false);

  const { isB2CUser, isLoading: isPlanLoading } = useFetchPlanQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      isB2CUser: getIsB2CUser(result.data),
    }),
  });

  const {
    disclaimerDesignationOptions,
    isLoading: isDesignationOptionsLoading,
  } = useFetchDesignationOptionsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      disclaimerDesignationOptions: selectDisclaimerDesignationOptions(
        result.data,
      ),
    }),
  });

  const [updateUserDesignations] = useUpdateUserDesignationsMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [completeOnboarding] = useCompleteOnboardingMutation();
  const [
    sendAccountLinkingEmail,
    {
      isLoading: isSendingAccountLinkingEmail,
      error: accountLinkingEmailError,
    },
  ] = useSendAccountLinkingEmailMutation();

  const handlePrev = useCallback(() => {
    const steps = getSteps(form, activeStep, {
      isB2CUser,
      disclaimerDesignationOptions,
    });

    setActiveStep(activeStepId => getPrevStepId({ steps, activeStepId }));
  }, [form, activeStep, isB2CUser, disclaimerDesignationOptions]);

  const handleNext = useCallback(async () => {
    const steps = getSteps(form, activeStep, {
      isB2CUser,
      disclaimerDesignationOptions,
    });
    const nextStepId = getNextStepId({ steps, activeStepId: activeStep });
    const isFinalStepOfDesignations = nextStepId === STEP_IDS.name;
    const isFinalStepOfOnboarding = steps[steps.length - 1].id === activeStep;

    if (isFinalStepOfDesignations) {
      const { userCountry, designations: userDesignations } = form;
      const result = await updateUserDesignations({
        userDesignations,
        userCountry,
      });

      if (result.error) {
        SnackbarUtils.error(
          'Failed to update designation, please try again, or contact support.',
        );
        return;
      }
    }

    if (activeStep === STEP_IDS.name) {
      updateUserProfile({
        firstName: form.name.first.trim(),
        lastName: form.name.last.trim(),
        referrer: 'onboarding',
      });
    }

    if (activeStep === STEP_IDS.email && form.email) {
      sendAccountLinkingEmail({ email: form.email.trim() });
    }

    if (!isFinalStepOfOnboarding) {
      setActiveStep(activeStepId => getNextStepId({ steps, activeStepId }));
    } else {
      completeOnboarding({
        userRegion: getDesignationCountry(form) === 'USA' ? 'USA' : 'CAN',
      });

      AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.FINISH);
      dispatch(uiActions.closeActiveModal());
    }
  }, [
    form,
    isB2CUser,
    activeStep,
    disclaimerDesignationOptions,
    dispatch,
    updateUserDesignations,
    updateUserProfile,
    sendAccountLinkingEmail,
    completeOnboarding,
  ]);

  useEffect(() => {
    if (goToNextStep) {
      handleNext();
      setGoToNextStep(false);
    }
  }, [goToNextStep, handleNext]);

  useEffect(() => {
    if (goToPrevStep) {
      handlePrev();
      setGoToPrevStep(false);
    }
  }, [goToPrevStep, handlePrev]);

  useEffect(() => {
    AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.STEP, {
      step_name: activeStep,
    });
  }, [activeStep]);

  const createHandlePrev = stepInputFormUpdater => {
    if (!stepInputFormUpdater) return null;

    return stepInput => {
      setForm(prevState => stepInputFormUpdater(prevState, stepInput));
      setGoToPrevStep(true);
    };
  };

  const createHandleNext = stepInputFormUpdater => {
    if (!stepInputFormUpdater) return null;

    return stepInput => {
      setForm(prevState => {
        logBreadcrumb({
          message: 'Onboarding Form stepInputFormUpdater invoked',
          data: {
            activeStep,
            stepInput: JSON.stringify(stepInput),
          },
        });
        return stepInputFormUpdater(prevState, stepInput);
      });
      setGoToNextStep(true);
    };
  };

  const steps = getSteps(form, activeStep, {
    isB2CUser,
    disclaimerDesignationOptions,
  });

  const isLoading = isPlanLoading || isDesignationOptionsLoading;

  return (
    <Box
      sx={{
        width: { xs: '100%', md: MODAL_HEIGHT },
        height: { md: MODAL_HEIGHT },
        overflow: 'hidden',
      }}>
      {isLoading ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <OnboardingFormStepsContext.Provider
          value={{
            steps,
            activeStep,
            disclaimers: getDisclaimerText(form, disclaimerDesignationOptions),
            country: getDesignationCountry(form),
            accountLinkingEmailError,
            isSendingAccountLinkingEmail,
          }}>
          {steps.map(({ id, handlePrev, handleNext, Component }) => (
            <Box
              key={id}
              sx={[
                { display: 'flex', width: '100%', height: '100%' },
                activeStep !== id && { display: 'none' },
              ]}>
              <Component
                goToPrevStep={createHandlePrev(handlePrev)}
                goToNextStep={createHandleNext(handleNext)}
              />
            </Box>
          ))}
        </OnboardingFormStepsContext.Provider>
      )}
    </Box>
  );
};
