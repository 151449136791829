import { Box } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { CheckboxButton } from '@/components';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

export const IsDesignatedForm = ({ goToNextStep }) => {
  const [form, setForm] = useState();

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} />
      <Box sx={styles.checkboxContainer}>
        <CheckboxButton
          label={"Yes, I'm designated"}
          checked={form === true}
          onChange={() => setForm(true)}
        />
      </Box>
      <Box sx={styles.checkboxContainer}>
        <CheckboxButton
          label={"No, I'm not designated"}
          checked={form === false}
          onChange={() => setForm(false)}
        />
      </Box>
      <Footer
        handleNext={() => goToNextStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </Box>
  );
};

const isFormValid = form => !R.isNil(form);

const Title = () => (
  <ModalTitle>Do you have an accounting or finance designation?</ModalTitle>
);
