import { Box, Typography } from '@mui/material';

export const TechnicalFilterPill = ({ isSelected, label, onClick }) => (
  <Box
    sx={[styles.pill, !!isSelected && styles.pillSelected]}
    onClick={onClick}>
    <Typography variant="pill2">{label}</Typography>
  </Box>
);

const styles = {
  pill: {
    height: 'fit-content',
    width: 'fit-content',
    cursor: 'pointer',
    color: 'grayDark',
    border: theme => `1.5px solid ${theme.palette.grayMid}`,
    borderRadius: 1.5,
    py: 0.5,
    px: 1.75,
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    WebkituserSelect: 'none',
    textWrap: 'nowrap',
    '&:hover': {
      backgroundColor: 'grayLight',
    },
  },
  pillSelected: {
    color: 'white',
    borderColor: 'teal',
    backgroundColor: 'teal',
    '&:hover': {
      borderColor: 'tealBright',
      backgroundColor: 'tealBright',
    },
  },
};
