import { useGrowthBook } from '@growthbook/growthbook-react';

import { AnalyticsService } from '@/services';
import { ageInHours } from '@/utils';

import { InitProps, useInitHook } from './useInitHook';

export const Growthbook = () => {
  const growthbook = useGrowthBook();

  useInitHook(
    ({ userId, userProfile, plan, userRegion, userTags }: InitProps) => {
      growthbook.setAttributes({
        id: userId,
        email: userProfile?.email,
        organizationName: plan?.organizationName,
        region: userRegion,
        userTags,
        application: 'web-client',
        url: window.location.href,
        userAgent: navigator.userAgent,
        signupDate: userProfile?.userCreatedAt,
        signupAge: ageInHours(userProfile?.userCreatedAt) || 0,
        onboardingDate: userProfile?.onboardingCompletedAt,
        onboardingAge: ageInHours(userProfile?.onboardingCompletedAt) || 0,
      });

      const features = Object.fromEntries(
        Object.keys(growthbook.getFeatures()).map(flag => [
          `$feature - ${flag}`,
          growthbook.getFeatureValue(flag, null),
        ]),
      );
      AnalyticsService.updateFeatureFlags(features);
    },
  );

  return null;
};
