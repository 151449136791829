import { Box, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CardInputElement } from '@/components';
import { selectUserRegion, uiActions } from '@/store';
import { formatCost } from '@/utils';

export const PaymentForm = ({ isLoading, totalCost, onCharge, onClose }) => {
  const dispatch = useDispatch();
  const region = useSelector(selectUserRegion);

  const onCloseClick = () => {
    dispatch(uiActions.closeActiveModal());
    onClose();
  };

  const currency = region === 'USA' ? 'USD' : 'CAD';

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h2" sx={{ textAlign: 'center', my: 2 }}>
        Enter your credit card information
      </Typography>
      <CardInputElement disabled={isLoading} />
      <Box
        className={{
          mt: 3,
          textAlign: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDiretion: 'row',
            justifyContent: 'center',
            mt: 3,
          }}>
          <Box sx={{ mr: 2 }}>
            <Button
              label="Cancel"
              variant="outlined"
              fullWidth={false}
              onClick={onCloseClick}
              disabled={isLoading}
            />
          </Box>
          <Button
            label={`Charge $${formatCost(totalCost)} ${currency}`}
            fullWidth={false}
            onClick={onCharge}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={24} />}
          />
        </Box>
      </Box>
    </Box>
  );
};
