import { Box, Button, Hidden, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Routes } from '@/constants';
import { useAccessInfo } from '@/hooks';

export const RemainingUsageBanner = () => {
  const navigate = useNavigate();
  const navbarAccessTypeInfo = useAccessInfo();

  if (!navbarAccessTypeInfo) return null;

  const {
    showExpiry,
    showUsage,
    showUpgradeBanner,
    infoTitle,
    usageRemaining,
    usageUnits,
    expiryDate,
  } = navbarAccessTypeInfo;

  if (!showUpgradeBanner) return null;

  return (
    <Hidden mdUp>
      <Box sx={styles.container}>
        {showExpiry && (
          <Box>
            <Typography sx={styles.topText}>Valid Until</Typography>
            <Typography sx={styles.bottomText}>{expiryDate}</Typography>
          </Box>
        )}
        {showUsage && (
          <Box>
            <Typography color="orange" variant="body2">
              {infoTitle}
            </Typography>
            <Typography color="orange" variant="h6">
              {`${usageRemaining} ${usageUnits} left`}
            </Typography>
          </Box>
        )}
        <Button
          sx={styles.upgradeButton}
          onClick={() => navigate(Routes.PRICING)}>
          Upgrade
        </Button>
      </Box>
    </Hidden>
  );
};

const styles = {
  container: {
    width: '100%',
    height: ({ dimensions }) => dimensions.header.height,
    background: 'beige',
    padding: theme =>
      `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(15)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  upgradeButton: {
    background: theme => theme.gradients.orangeDarkToLight,
    color: 'white',
    padding: theme =>
      `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(10)}`,
  },
};
