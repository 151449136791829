import {
  AssignmentRounded,
  ConfirmationNumberRounded,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { AppButton } from '@/components';
import { Modals } from '@/constants';
import {
  selectIsEpisodeRedeemRequired,
  selectUserRegion,
  uiActions,
  userEpisodesThunks,
} from '@/store';

export const Assessment = ({ episodeId }) => {
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );
  const region = useSelector(selectUserRegion);
  const dispatch = useDispatch();

  return region === 'CAN' ? null : (
    <Box
      sx={theme => ({
        mt: 2,
        p: 2.5,
        borderRadius: 1,
        background: theme.gradients.orange,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      })}>
      <Typography variant="h3" sx={{ color: 'white', mr: 2 }}>
        Final Assessment
      </Typography>
      {isEpisodeRedeemRequired ? (
        <AppButton
          variant="secondary"
          onClick={() => {
            dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
          }}>
          <ConfirmationNumberRounded sx={{ mr: 1 }} />
          <Typography variant="button2">Redeem</Typography>
        </AppButton>
      ) : (
        <AppButton
          variant="secondary"
          onClick={() => {
            dispatch(
              uiActions.setActiveModal({
                name: Modals.ASSESSMENT,
                params: { episodeId },
              }),
            );
          }}>
          <AssignmentRounded sx={{ mr: 1 }} />
          <Typography variant="button2">View Assessment</Typography>
        </AppButton>
      )}
    </Box>
  );
};
