import { format } from 'date-fns';

import {
  createCustomCPDRecordRequest,
  deleteCustomCPDRecordRequest,
  fetchCertificateDownloadUrlRequest,
  fetchCertificateDownloadUrlsRequest,
  fetchCertificateSummaryRequest,
  fetchTriennialSummaryRequest,
  updateCustomCPDRecordRequest,
  uploadCPDRecordFileRequest,
} from '@/requests';

import { nodeApi } from '../node-api';
import { postgrestApi } from '../postgrest-api';
import { NodeApiTags, PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError, rtkQueryError } from '../store.utils';
import { DEFAULT_DATE_RANGE } from './tracker-node-api.constants';
import { saveCertificateZip } from './tracker-node-api.utils';

export const trackerNodeApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchTriennialSummary: builder.query({
      query: fetchTriennialSummaryRequest,
      transformResponse: response => response.data,
      providesTags: [NodeApiTags.TRIENNIAL_SUMMARY],
    }),
    downloadCertificate: builder.mutation({
      queryFn: async ({ certificateId }, redux, extraOptions, baseQuery) => {
        try {
          const { data, error: downloadUrlError } = await baseQuery(
            fetchCertificateDownloadUrlRequest({ certificateId }),
            redux,
            extraOptions,
          );

          if (downloadUrlError) return { error: downloadUrlError };

          const url = data?.data?.url;

          if (!url)
            return {
              error: 'Certificate not found.',
            };

          window.open(url, '_self');
          return { data: null };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
    }),
    downloadCertificateSummary: builder.mutation({
      queryFn: async ({ fromDate, toDate }, redux, extraOptions, baseQuery) => {
        try {
          const fromDateFormatted = fromDate
            ? format(fromDate, 'yyyy-MM-dd')
            : DEFAULT_DATE_RANGE.fromDate;
          const toDateFormatted = toDate
            ? format(toDate, 'yyyy-MM-dd')
            : DEFAULT_DATE_RANGE.toDate;

          const { data, error: downloadUrlsError } = await baseQuery(
            fetchCertificateDownloadUrlsRequest({
              fromDate: fromDateFormatted,
              toDate: toDateFormatted,
            }),
            redux,
            extraOptions,
          );

          if (downloadUrlsError) return { error: downloadUrlsError };

          const certificates = data.data;

          const certificateFiles = [];
          await Promise.all(
            certificates.map(async certificate => {
              const response = await fetch(certificate.url);
              const data = await response.blob();
              certificateFiles.push({ ...certificate, content: data });
            }),
          );

          const { data: summaryFile, error: downloadSummaryError } =
            await baseQuery(
              {
                ...fetchCertificateSummaryRequest({
                  fromDate: fromDateFormatted,
                  toDate: toDateFormatted,
                }),
                responseHandler: response => response.blob(),
              },
              redux,
              extraOptions,
            );

          if (downloadSummaryError) return { error: downloadSummaryError };

          await saveCertificateZip({ certificateFiles, summaryFile });

          return { data: null };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
    }),
    createCustomCPDRecord: builder.mutation({
      queryFn: async (args, redux, extraOptions, baseQuery) => {
        let fileData = {};
        try {
          if (args.file) {
            const formData = new FormData();
            formData.append('file', args.file);
            const { data, error } = await baseQuery(
              uploadCPDRecordFileRequest({ formData }),
              redux,
              extraOptions,
            );
            if (error) return { error };
            fileData = data;
          }

          const { error } = await baseQuery(
            createCustomCPDRecordRequest({ ...args, ...fileData }),
            redux,
            extraOptions,
          );

          if (error) return { error };

          return { data: null };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            nodeApi.util.invalidateTags([NodeApiTags.TRIENNIAL_SUMMARY]),
          );
          dispatch(
            postgrestApi.util.invalidateTags([PostgrestApiTags.PD_HOURS]),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    updateCustomCPDRecord: builder.mutation({
      queryFn: async (
        { newPdHour, pdHourId },
        redux,
        extraOptions,
        baseQuery,
      ) => {
        let fileData = {};
        if (
          newPdHour.file &&
          newPdHour.file?.fileUri !== newPdHour.origFile?.fileUri
        ) {
          const formData = new FormData();
          formData.append('file', newPdHour.file);
          const { data, error } = await baseQuery(
            uploadCPDRecordFileRequest({ formData }),
            redux,
            extraOptions,
          );
          if (error) return { error };
          fileData = data;
        } else {
          fileData = {
            fileName: newPdHour.origFile?.name,
            fileSize: newPdHour.origFile?.size,
            fileUri: newPdHour.origFile?.fileUri,
          };
        }
        const { error } = await baseQuery(
          updateCustomCPDRecordRequest({
            ...newPdHour,
            ...fileData,
            id: pdHourId,
          }),
          redux,
          extraOptions,
        );

        if (error) return { error };

        return { data: null };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            nodeApi.util.invalidateTags([NodeApiTags.TRIENNIAL_SUMMARY]),
          );
          dispatch(
            postgrestApi.util.invalidateTags([PostgrestApiTags.PD_HOURS]),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    deleteCustomCPDRecord: builder.mutation({
      query: deleteCustomCPDRecordRequest,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            nodeApi.util.invalidateTags([NodeApiTags.TRIENNIAL_SUMMARY]),
          );
          dispatch(
            postgrestApi.util.invalidateTags([PostgrestApiTags.PD_HOURS]),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
  }),
});

export const {
  useFetchTriennialSummaryQuery,
  useDownloadCertificateMutation,
  useDownloadCertificateSummaryMutation,
  useCreateCustomCPDRecordMutation,
  useUpdateCustomCPDRecordMutation,
  useDeleteCustomCPDRecordMutation,
} = trackerNodeApi;
