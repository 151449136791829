import { LUMIQ_ORGANIZATION_NAME } from '@/constants';

export const sortLearningPaths = lps => {
  if (!lps) return { orgLps: [], featuredLps: [], allOtherLps: [] };

  const isOrgLp = lp => lp.organizationName !== LUMIQ_ORGANIZATION_NAME;
  const isFeaturedLp = lp => lp.featured;

  const orgLps = lps.filter(isOrgLp);
  const featuredLps = lps.filter(isFeaturedLp);
  const allOtherLps = lps.filter(lp => !isOrgLp(lp) && !isFeaturedLp(lp));

  return { orgLps, featuredLps, allOtherLps };
};

export const calculateDurationLeft = ({ lps, userEpisodes }) => {
  if (!lps) return undefined;

  return lps.map(lp => ({
    ...lp,
    durationPlayed: lp.episodes.reduce(
      (sum, episode) =>
        sum + (userEpisodes[episode.episodeId]?.playedDuration || 0),
      0,
    ),
  }));
};
