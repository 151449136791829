import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { BannerSlide } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import { selectUserRegion, useFetchFeaturedEpisodesQuery } from '@/store';

import {
  EPISODE_BANNER_MAX_WIDTH,
  EpisodeBanner,
  EpisodeBannerSkeleton,
} from '../EpisodeBanner';

export const FeaturedEpisodesBannerSlide = () => {
  const userRegion = useSelector(selectUserRegion);

  const {
    data: featuredEpisodes,
    isLoading,
    isError,
  } = useFetchFeaturedEpisodesQuery({
    region: userRegion,
  });

  return (
    <>
      {isLoading && <EpisodeBannerSkeleton />}
      {!isLoading && isError && null}
      {!isLoading && !isError && (
        <Box sx={{ maxWidth: EPISODE_BANNER_MAX_WIDTH, margin: 'auto' }}>
          <BannerSlide>
            {featuredEpisodes.map(episode => (
              <EpisodeBanner
                key={episode.episodeId}
                episode={episode}
                referrer={{
                  page: EVENT_CONSTANTS.PAGE.DISCOVER,
                  component: EVENT_CONSTANTS.COMPONENT.FEATURED_BANNER,
                }}
              />
            ))}
          </BannerSlide>
        </Box>
      )}
    </>
  );
};
