import { Skeleton as MuiSkeleton } from '@mui/material';

export const Skeleton = ({
  variant = 'text',
  width = '100%',
  height = '100%',
  animation = 'wave',
}) => (
  <MuiSkeleton
    animation={animation}
    sx={{ bgcolor: 'grayNeutral', width, height }}
    variant={variant}
  />
);
