import { enqueueSnackbar } from 'notistack';

const toast = (msg, options = { variant: 'default' }) =>
  enqueueSnackbar(msg, { preventDuplicate: true, ...options });

export const SnackbarUtils = {
  success: msg => toast(msg, { variant: 'success' }),
  warning: msg => toast(msg, { variant: 'warning' }),
  info: msg => toast(msg, { variant: 'info' }),
  error: msg => toast(msg, { variant: 'error' }),
};
