import { Box, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';

import { headphoneIcon, scaleIcon, whiteboardIcon } from '@/images';

import { selectPDHourTotalsCanada } from '../utils';
import { DateRangeSelector } from './DateRangeSelector';
import { OverviewChart } from './OverviewChart';
import { OverviewCredit } from './OverviewCredit';
import { OverviewSummary } from './OverviewSummary';

export const Overview = ({
  credits,
  yearlyRequirement,
  dateRange,
  setDateRange,
  province,
}) => {
  const totalCredits = useMemo(() => {
    return selectPDHourTotalsCanada(credits);
  }, [credits]);

  return (
    <Box sx={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={styles.container}>
            <Typography variant="h2" sx={{ color: 'blueBlack', pr: 2 }}>
              Overview
            </Typography>
            <DateRangeSelector
              province={province}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <OverviewSummary
            totalCredits={totalCredits.total}
            yearlyRequirement={yearlyRequirement}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OverviewChart
            credits={totalCredits}
            yearlyRequirement={yearlyRequirement}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={styles.line} />
        </Grid>
        <Grid item xs={12} md={4}>
          <OverviewCredit
            credits={totalCredits.lumiq}
            image={headphoneIcon}
            label="LumiQ Hours"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <OverviewCredit
            credits={totalCredits.external}
            image={whiteboardIcon}
            label="External Hours"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <OverviewCredit
            credits={totalCredits.ethics}
            image={scaleIcon}
            label="Ethics Hours"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  root: {
    border: theme => `solid 1px ${theme.palette.grayBrightLight}`,
    borderRadius: 2,
    boxShadow: theme => `0 2px 5px 0 ${theme.palette.grayBrightLight}`,
    boxSizing: 'border-box',
    p: 3,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    pb: 2,
  },
  line: {
    border: theme => `solid 1px ${theme.palette.grayBrightLight}`,
    boxSizing: 'border-box',
    height: 0,
    width: '100%',
  },
};
