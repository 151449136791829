import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext } from 'react';

import { urls } from '@/constants';
import { Link } from '@/containers';
import { emailSent } from '@/images';

import { OnboardingFormStepsContext } from '../OnboardingForm';
import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const EmailSent = ({ goToPrevStep, goToNextStep }) => {
  const { isSendingAccountLinkingEmail, accountLinkingEmailError } = useContext(
    OnboardingFormStepsContext,
  );

  return (
    <Box sx={styles.formContainer}>
      {isSendingAccountLinkingEmail && (
        <Header Title={<Title />} Subtitle={<LoadingSubtitle />} />
      )}
      {!isSendingAccountLinkingEmail && accountLinkingEmailError && (
        <Header Title={<ErrorTitle />} Subtitle={<ErrorSubtitle />} />
      )}
      {!isSendingAccountLinkingEmail && !accountLinkingEmailError && (
        <Header Title={<Title />} Subtitle={<Subtitle />} />
      )}

      <Box
        sx={[
          styles.formPrimarySection,
          {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}>
        {isSendingAccountLinkingEmail ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <img
              src={emailSent}
              style={{
                width: 165,
                height: 165,
              }}
              alt="email sent"
            />
          </Box>
        )}

        {!accountLinkingEmailError && !isSendingAccountLinkingEmail && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography>
              Didn&apos;t get an email? Check your spam folder!
            </Typography>

            <Link onClick={goToPrevStep}>
              Re-enter your work email and try again.
            </Link>
          </Box>
        )}
      </Box>
      <Footer
        handlePrev={() => goToPrevStep()}
        handleNext={() => goToNextStep()}
        isPrevButtonDisabled={isSendingAccountLinkingEmail}
        isNextButtonDisabled={isSendingAccountLinkingEmail}
      />
    </Box>
  );
};

const Title = () => <ModalTitle>Check Your Email</ModalTitle>;

const ErrorTitle = () => <ModalTitle>Email not found</ModalTitle>;

const Subtitle = () => (
  <ModalSubtitle>
    We&apos;ve matched your email address to a corporate plan. Go check your
    email and follow the instructions to accept your invitation.
  </ModalSubtitle>
);

const LoadingSubtitle = () => (
  <ModalSubtitle>
    Expect to receive an email in your inbox shortly.
  </ModalSubtitle>
);

const ErrorSubtitle = () => (
  <ModalSubtitle>
    Our system could not match your work email to a company plan. Please contact
    your company administrator or reach out to {urls.supportEmail} for support.
  </ModalSubtitle>
);
