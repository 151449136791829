import { useMemo } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { RoutesWithLayout } from '@/constants';
import { AppLayout, ErrorBoundary, PageErrorBoundary } from '@/containers';
import { useRouteProtection } from '@/hooks';

export const AppRouteWrapper = () => {
  const { isAllowed } = useRouteProtection();
  const { pathname } = useLocation();

  const isRouteWithLayout = useMemo(() => {
    return Object.values(RoutesWithLayout).some(route =>
      matchPath(route, pathname),
    );
  }, [pathname]);

  if (!isAllowed) return null;

  const Content = (
    <ErrorBoundary key={pathname} View={PageErrorBoundary}>
      <Outlet />
    </ErrorBoundary>
  );

  if (isRouteWithLayout) return <AppLayout>{Content}</AppLayout>;

  return Content;
};
