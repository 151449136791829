import { Box, Typography } from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectUserRegion,
  useFetchLearningPathsQuery,
  userEpisodesSelectors,
} from '@/store';

import { LearningPathsGrid } from './LearningPathsGrid';
import { calculateDurationLeft, sortLearningPaths } from './utils';

export const LearningPaths = () => {
  const userRegion = useSelector(selectUserRegion);
  const userEpisodes = useSelector(userEpisodesSelectors.selectEntities);

  const {
    data: learningPaths,
    isLoading,
    isUninitialized,
  } = useFetchLearningPathsQuery({
    region: userRegion,
  });

  const { orgLps, allOtherLps, featuredLps } = useMemo(() => {
    return R.pipe(
      lps => calculateDurationLeft({ lps, userEpisodes }),
      sortLearningPaths,
    )(learningPaths);
  }, [userEpisodes, learningPaths]);

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h1" gutterBottom>
          Learning Paths
        </Typography>
        <Typography variant="subtitle1">
          Achieve your goals with Learning Paths — a guided set of podcasts that
          will help you develop mastery of specific skills or topics.
        </Typography>
      </Box>
      {orgLps.length > 0 && (
        <Box mb={4}>
          <Typography variant="h3" gutterBottom>
            {`${orgLps[0].organizationName}'${orgLps[0].organizationName.slice(-1) !== 's' ? 's' : ''}`}{' '}
            Learning Paths
          </Typography>
          <LearningPathsGrid lps={orgLps} />
        </Box>
      )}
      <Box mb={4}>
        <Typography variant="h3" gutterBottom>
          Featured Learning Paths
        </Typography>
        <LearningPathsGrid
          lps={featuredLps}
          isLoading={isLoading || isUninitialized}
        />
      </Box>
      <Box mb={4}>
        <Typography variant="h3" gutterBottom>
          All Other Learning Paths
        </Typography>
        <LearningPathsGrid
          lps={allOtherLps}
          isLoading={isLoading || isUninitialized}
        />
      </Box>
    </Box>
  );
};
