import { Warning } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export const WarningBanners = ({ episode }) => {
  return (
    <>
      {episode.isArchived && (
        <Box sx={styles.warningBox}>
          <Warning sx={styles.warningBoxIcon} />
          <Typography>This episode has been archived</Typography>
        </Box>
      )}
    </>
  );
};

const styles = {
  warningBox: {
    backgroundColor: 'beige',
    color: 'orange',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1,
    textAlign: 'center',
    p: 3,
  },
  warningBoxIcon: {
    mr: 1,
  },
};
