import { Menu } from '@mui/icons-material';
import { AppBar, Box, Hidden, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';

import { uiActions } from '@/store';

import {
  NavbarActions,
  NavbarLogo,
  NavbarPricing,
  NavBarRemainingUsage,
  RegionBanner,
  RemainingUsageBanner,
  SearchBar,
} from './components';

export const AppHeader = () => {
  const dispatch = useDispatch();

  const toggleSidebarDrawer = () => {
    dispatch(uiActions.toggleSidebarDrawer());
  };

  return (
    <AppBar position="fixed" sx={styles.appbar}>
      <RegionBanner />
      <RemainingUsageBanner />
      <Box sx={styles.root}>
        <Hidden lgUp>
          <IconButton
            sx={{ color: 'text.white', ml: 1 }}
            aria-label="open side drawer"
            onClick={toggleSidebarDrawer}>
            <Menu />
          </IconButton>
        </Hidden>
        <NavbarLogo />
        <Box sx={styles.rightContainer}>
          <SearchBar />
          <NavBarRemainingUsage />
          <NavbarPricing />
          <NavbarActions />
        </Box>
      </Box>
    </AppBar>
  );
};

const styles = {
  appbar: {
    boxShadow: 'none',
    [`& .MuiButton-root`]: {
      // TODO this is not how we want to customize
      color: 'text.white',
    },
  },
  root: {
    width: '100%',
    height: ({ dimensions }) => dimensions.header.height,
    background: ({ gradients }) => gradients.blueToAqua,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    pr: 1,
  },
};
