import { GrowthBook } from '@growthbook/growthbook-react';
import mixpanel from 'mixpanel-browser';

import config from '@/config';
import { urls } from '@/constants';

export const growthbook = new GrowthBook({
  apiHost: urls.actions.growthbook,
  clientKey: config.GROWTHBOOK_CLIENT_CODE,
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback: (experiment, result) => {
    // TODO: May need to wire in to growthpanel init if distinct_id doesn't match
    mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    });
  },
});

// Note: growthbook will fail to load in test environments
if (process.env.NODE_ENV !== 'test') {
  growthbook.init({ streaming: true });
}
