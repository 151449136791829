export const alphanumericSort = (a, b, { direction = 'asc' } = {}) => {
  // Ensure empty values are always at the end
  const number = direction === 'asc' ? 1 : -1;
  if (a === '') {
    if (b === '') return 0;
    return number;
  }
  if (b === '') return -number;

  // Sort alphanumeric strings with numeric collation and case insensitivity
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
};
