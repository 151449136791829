import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Box, Button, Grid, Hidden } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { EVENT_CONSTANTS } from '@/services';
import {
  playerActions,
  selectActiveChapterId,
  selectIsPlayerExpanded,
  selectUserRegion,
  useFetchChapterQuery,
} from '@/store';

import { ProgressBar } from '../ProgressBar';
import { VolumeSlider } from '../VolumeSlider';
import { ChapterInfo, MediaControls, ProgressInfo } from './components';

export const MiniPlayer = () => {
  const dispatch = useDispatch();
  const activeChapterId = useSelector(selectActiveChapterId);
  const isExpanded = useSelector(selectIsPlayerExpanded);
  const region = useSelector(selectUserRegion);
  const { isVideo } = useFetchChapterQuery(
    {
      chapterId: activeChapterId,
      region,
    },
    {
      selectFromResult: result => ({
        isVideo: result.data?.type === 'video',
      }),
    },
  );

  const location = { component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER };

  const onToggleExpanded = () => {
    dispatch(playerActions.setExpanded(!isExpanded));
  };

  return (
    <Box sx={styles.miniPlayerContainer}>
      <Box sx={styles.progressBarContainer}>
        <ProgressBar referrer={location} />
      </Box>
      <Grid container alignItems="center">
        <Grid item xs={3} sm={4} md={4} lg={4}>
          <ChapterInfo referrer={location} />
        </Grid>
        <Grid item xs={4} sm={5} md={5} lg={4}>
          <MediaControls />
        </Grid>
        <Grid item xs={5} sm={3} md={3} lg={4}>
          <Box sx={styles.playerRight}>
            <ProgressInfo />
            <Hidden lgDown>
              <Box sx={styles.volumeControls}>
                <VolumeSlider referrer={location} />
              </Box>
            </Hidden>
            {isVideo && (
              <Button
                variant="solid"
                sx={styles.expandButton}
                colour="gray"
                onClick={onToggleExpanded}>
                {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  miniPlayerContainer: theme => ({
    position: 'relative',
    backgroundColor: 'blueBlack',
    boxShadow: '0 -5px 20px 0 rgba(0,0,0,0.5)',
    color: 'white',
    height: theme.typography.pxToRem(90),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
    },
  }),
  progressBarContainer: theme => ({
    bottom: 76,
    ml: theme.typography.pxToRem(90),
    position: 'absolute',
    width: 'calc(100% - 90px)',
    [theme.breakpoints.down('sm')]: {
      bottom: 52,
      ml: theme.typography.pxToRem(66),
      width: 'calc(100% - 66px)',
    },
  }),
  playerRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-end',
    pt: 0.5,
  },
  volumeControls: {
    mr: 2.5,
  },
  expandButton: theme => ({
    height: theme.typography.pxToRem(50),
    minHeight: theme.typography.pxToRem(32),
    minWidth: theme.typography.pxToRem(32),
    padding: 0,
    width: theme.typography.pxToRem(50),
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(44),
      width: theme.typography.pxToRem(44),
    },
  }),
};
