import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Button } from '@/components';
import { urls } from '@/constants';
import { uiActions } from '@/store';

export const PaymentError = ({ onClose, onBack }) => {
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(uiActions.closeActiveModal());
    onClose();
  };
  return (
    <Box>
      <Typography variant="h2" sx={{ textAlign: 'center', my: 2 }}>
        Purchase Failed
      </Typography>
      <Box>
        <Typography>
          There was an error processing your payment information.
          <br /> Please contact{' '}
          <a href={`mailto:${urls.supportEmail}`}>{urls.supportEmail}</a> for
          support.
        </Typography>
        <Typography></Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 4,
        }}>
        <Box sx={{ mr: 2 }}>
          <Button label="Back" fullWidth={false} onClick={onBack} />
        </Box>
        <Button
          label="Cancel"
          variant="outlined"
          fullWidth={false}
          onClick={onCloseClick}
        />
      </Box>
    </Box>
  );
};
