export const createRouteWithParams = (route, params) => {
  let result = route;
  Object.keys(params).forEach(key => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
};

export const getIsBranchLink = search => {
  return !!new URLSearchParams(search).get('_branch_match_id');
};

export const getUtmParameters = search => {
  return Array.from(new URLSearchParams(search).entries()).reduce(
    (obj, [param, value]) =>
      param.startsWith('utm_') ? { ...obj, [param]: value } : obj,
    {},
  );
};
