import { Chip } from '@mui/material';

export const EpisodeCategoryPill = props => {
  return (
    <Chip
      size="small"
      sx={theme => ({
        ...theme.typography.pill,
        width: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        color: 'grayBright',
        background: 'transparent',
        border: theme => `2px solid ${theme.palette.grayBright}`,
      })}
      {...props}
    />
  );
};
