import {
  Bookmark,
  ConfirmationNumberRounded,
  PlayArrow,
  Share,
  VolumeUp,
} from '@mui/icons-material';
import { Box, Button, Hidden, Typography } from '@mui/material';

import { EpisodeCategoryPill, EthicsPill, PremiumPill } from '@/components';
import { Routes } from '@/constants';
import { createRouteWithParams } from '@/utils';

import { Link } from '../Link';

export const EpisodeBanner = ({
  episode,
  onTrailer,
  onPlayNow,
  onShare,
  onRedeem,
  actions,
  toggleBookmark,
  isBookmarking,
  isBookmarked,
  isEpisodeEthics,
  isFreeTrialUser,
  isEpisodeRedeemRequired,
  categoryTags,
  disableTitleLink,
  referrer,
}) => (
  <Box
    sx={{
      color: 'text.white',
      bgcolor: 'blueBlack',
      backgroundImage: ({ gradients }) =>
        `${gradients.seriesCoverGeneral}, url(${episode.cover})`,
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
      maxWidth: MAX_WIDTH,
      margin: '0 auto',
      minHeight: HEIGHT,
    }}>
    <Box sx={styles.container}>
      <Box sx={styles.topContainer}>
        <Box sx={{ flexGrow: 1 }}>
          <Link
            to={
              disableTitleLink
                ? null
                : createRouteWithParams(Routes.EPISODE, {
                    episodeId: episode.episodeId,
                  })
            }
            state={{ referrer }}
            color="inherit">
            <Typography variant="h2" gutterBottom>
              {episode.name}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.descriptionContainer}>
          <Box sx={{ maxWidth: '700px' }}>
            <Typography variant="subtitle1" gutterBottom>
              {episode.hook}
            </Typography>
          </Box>
        </Box>
        <Typography variant="caption">{episode.supplemental}</Typography>
        <Box sx={styles.pillContainer}>
          {isEpisodeEthics && (
            <Box sx={{ m: 0.25 }}>
              <EthicsPill
                region={episode.region}
                ethicsDuration={episode.totalEthics}
                showDuration={false}
              />
            </Box>
          )}
          {isFreeTrialUser && episode.isPremium && (
            <Box sx={{ m: 0.25 }}>
              <PremiumPill />
            </Box>
          )}
          <Hidden smDown>
            {categoryTags?.map(cat => (
              <Box key={cat.id} sx={{ m: 0.25 }}>
                <EpisodeCategoryPill label={cat.name} />
              </Box>
            ))}
          </Hidden>
        </Box>
      </Box>
      {actions || (
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="warning"
            onClick={onTrailer}
            disabled={episode.isArchived}
            sx={[
              styles.textButton,
              { background: theme => theme.gradients.orange },
            ]}>
            <VolumeUp />
            <Typography sx={styles.buttonLabel}>Preview</Typography>
          </Button>
          {isEpisodeRedeemRequired ? (
            <Button
              variant="text"
              onClick={onRedeem}
              sx={[styles.textButton, styles.clearButton, styles.borderButton]}>
              <ConfirmationNumberRounded />
              <Hidden only={['xs']}>
                <Typography sx={styles.buttonLabel}>
                  Redeem<Hidden mdDown> Episode</Hidden>
                </Typography>
              </Hidden>
            </Button>
          ) : (
            <Button
              variant="text"
              onClick={onPlayNow}
              disabled={episode.isArchived}
              sx={[styles.textButton, styles.clearButton]}>
              <PlayArrow />
              <Hidden only={['xs', 'sm']}>
                <Typography sx={styles.buttonLabel}>Play Now</Typography>
              </Hidden>
            </Button>
          )}
          <Button
            variant="text"
            onClick={toggleBookmark}
            disabled={episode.isArchived || isBookmarking}
            sx={[
              styles.textButton,
              styles.clearButton,
              isBookmarked && styles.activeButton,
            ]}>
            <Bookmark />
            <Hidden only={['xs', 'sm']}>
              <Typography sx={styles.buttonLabel}>Bookmark</Typography>
            </Hidden>
          </Button>
          <Button
            variant="text"
            onClick={onShare}
            disabled={episode.isArchived}
            sx={[styles.textButton, styles.clearButton]}>
            <Share />
            <Hidden only={['xs', 'sm']}>
              <Typography sx={styles.buttonLabel}>Share</Typography>
            </Hidden>
          </Button>
          {!!episode.companyLogo && (
            <Hidden smDown>
              <Box sx={styles.logoContainer}>
                <Box
                  component="img"
                  src={`${episode.companyLogo}?width=100`}
                  alt="company logo"
                  sx={styles.logoImage}
                />
              </Box>
            </Hidden>
          )}
        </Box>
      )}
    </Box>
  </Box>
);

export const HEIGHT = 260;
export const MAX_WIDTH = 1360;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2.5,
    width: '100%',
    minHeight: HEIGHT,
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  descriptionContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textButton: {
    color: 'white',
    fontSize: theme => theme.typography.pxToRem(14),
    textTransform: 'none',
    p: 1.5,
    minWidth: 'auto',
    '&:not(:first-of-type)': {
      ml: 1,
    },
    opactity: 1,
    '&:hover': {
      opacity: 0.8,
    },
    transitionProperty: 'opacity, background-color',
    transitionDuration: '300ms',
  },
  clearButton: {
    '&:hover': {
      backgroundColor: 'tealTransparent',
    },
  },
  borderButton: {
    border: theme => `1px solid ${theme.palette.white}`,
  },
  activeButton: {
    svg: {
      color: 'tealBright',
    },
  },
  buttonLabel: {
    ml: 1.5,
  },
  logoContainer: {
    ml: 'auto',
    mt: 'auto',
    position: 'relative',
  },
  logoImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: {
      xs: 50,
      md: 80,
    },
    width: {
      xs: 50,
      md: 80,
    },
    borderRadius: 1.5,
  },
  pillContainer: {
    mt: 1,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
};
