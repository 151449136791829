import { Box, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppButton, Button } from '@/components';
import { Modals, Routes } from '@/constants';
import { check, ticket, ticketDisabled } from '@/images';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveModalParams,
  selectIsB2CUser,
  selectIsEpisodeRedeemRequired,
  selectIsPlaybackRejected,
  selectPlaybackRejectedChapter,
  selectUserRegion,
  uiActions,
  useFetchEpisodeQuery,
  useFetchPlanQuery,
  useRedeemEpisodeMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const RedeemModalContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { episodeId } = useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);
  const isB2CUser = useSelector(selectIsB2CUser);
  const { data: plan } = useFetchPlanQuery();
  const { data: episode } = useFetchEpisodeQuery(
    { episodeId, region },
    { skip: !episodeId },
  );
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );
  const isPlaybackRejected = useSelector(selectIsPlaybackRejected);
  const playbackRejectedChapter = useSelector(selectPlaybackRejectedChapter);

  const [redeemEpisode, { isLoading }] = useRedeemEpisodeMutation();

  const onRedeemEpisode = async () => {
    try {
      await redeemEpisode({ episodeId }).unwrap();
      AnalyticsService.episodeRedeem({ episode });
      if (
        isPlaybackRejected &&
        playbackRejectedChapter.episodeId === episodeId
      ) {
        dispatch(
          playerThunks.changeActiveChapter({
            activeChapter: playbackRejectedChapter,
            location: {
              component: EVENT_CONSTANTS.COMPONENT.MODAL,
              modalName: Modals.REDEEM,
              details: EVENT_CONSTANTS.AUTO_PLAY,
            },
          }),
        );
      }
    } catch (error) {
      SnackbarUtils.error('Failed to redeem episode');
    }
  };

  const onBuySubscription = () => {
    if (isB2CUser) {
      navigate(Routes.PRICING);
    } else {
      window.Intercom?.('show');
    }
    dispatch(uiActions.closeActiveModal());
  };

  if (!episode) {
    return (
      <Box sx={styles.container}>
        <CircularProgress sx={{ my: 10 }} />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {isEpisodeRedeemRequired ? (
        <>
          {plan.creditsRemaining > 0 && (
            <img src={ticket} alt="" style={{ maxWidth: 114 }} />
          )}
          {plan.creditsRemaining <= 0 && (
            <img src={ticketDisabled} alt="" style={{ maxWidth: 114 }} />
          )}
          <Typography variant="h6" mt={4}>
            You have {plan.creditsRemaining} credits Available
          </Typography>
          <Typography variant="h6">
            {plan.creditsRemaining > episode.CPEDetails.credits &&
              `Use ${episode.CPEDetails.credits} Credits for:`}
            {plan.creditsRemaining < episode.CPEDetails.credits &&
              plan.creditsRemaining > 0 &&
              'Use Remaining Credits for:'}
            {plan.creditsRemaining <= 0 &&
              `You'll need ${episode.CPEDetails.credits} Credits for:`}
          </Typography>
          <Typography variant="h6" sx={{ mt: 4, color: 'teal' }}>
            {episode.name}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : plan.creditsRemaining > 0 ? (
            <AppButton variant="teal" sx={{ mt: 3 }} onClick={onRedeemEpisode}>
              <Typography variant="h6">Redeem</Typography>
            </AppButton>
          ) : (
            <AppButton
              variant="orange"
              sx={{ mt: 3 }}
              onClick={onBuySubscription}>
              <Typography variant="h6">
                {isB2CUser ? 'Buy Subscription' : 'Message us to get credits'}
              </Typography>
            </AppButton>
          )}
          <Box mt={2}>
            <Button
              variant="secondary"
              label="Cancel"
              fullWidth={false}
              onClick={() => {
                dispatch(uiActions.closeActiveModal());
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <img src={check} alt="" style={{ maxWidth: 114 }} />
          <Typography variant="body1" mt={4}>
            Episode Redeemed!
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            You have {plan.creditsRemaining} credits remaining
          </Typography>
        </>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    width: 350,
    maxHeight: 470,
    p: 3,
    pt: 4,
  },
};
