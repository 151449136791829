import { useSelector } from 'react-redux';
import { selectBookmarkedEpisodeIds } from 'src/pages/MyLumiq/utils';

import { EpisodeCardCarousel } from '@/components';
import { CAROUSEL_EPISODES_LIMIT, Routes } from '@/constants';
import { Link } from '@/containers';
import {
  selectSessionUserId,
  selectUserRegion,
  useFetchEpisodesQuery,
  useFetchUserEpisodesQuery,
} from '@/store';

export const BookmarkedEpisodesCarousel = () => {
  const userRegion = useSelector(selectUserRegion);
  const userId = useSelector(selectSessionUserId);

  const { bookmarkedEpisodeIds } = useFetchUserEpisodesQuery(undefined, {
    skip: !userId,
    selectFromResult: result => ({
      bookmarkedEpisodeIds: selectBookmarkedEpisodeIds(
        Object.values(result?.data?.entities || {}),
      ).slice(0, CAROUSEL_EPISODES_LIMIT),
    }),
  });

  const { data: episodes } = useFetchEpisodesQuery(
    {
      episodeIds: bookmarkedEpisodeIds,
      region: userRegion,
    },
    {
      skip: !bookmarkedEpisodeIds?.length,
    },
  );

  const ViewAllLink = () => (
    <Link to={Routes.MY_LUMIQ} state={{ tab: 'bookmarked' }}>
      View All
    </Link>
  );

  return (
    <>
      {episodes?.length > 0 && (
        <EpisodeCardCarousel
          title="Bookmarked Episodes"
          uKey="Bookmarked Episodes"
          episodes={episodes}
          ViewAllLink={ViewAllLink}
          referrer={{ page: 'discover', category: 'bookmarked episodes' }}
        />
      )}
    </>
  );
};
