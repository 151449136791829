import { fetchEpisodeAssessmentRequest } from '@/requests';
import { Assessment } from '@/types';
import { camelCaseKeys } from '@/utils';

import { postgrestApi } from '../postgrest-api';

export const assessmentsApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchEpisodeAssessment: builder.query<Assessment, { episodeId: string }>({
      query: fetchEpisodeAssessmentRequest,
      transformResponse: (response: unknown[]) => camelCaseKeys(response[0]),
    }),
  }),
});

export const { useFetchEpisodeAssessmentQuery } = assessmentsApi;
