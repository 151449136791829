import { Box } from '@mui/material';

import { lumiqWhiteLogoWatermark, welcome } from '@/images';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

export const Welcome = ({ goToNextStep }) => {
  return (
    <Box
      sx={[
        styles.formContainer,
        {
          backgroundImage: `url(${welcome})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        },
      ]}>
      <Header Title={<Title />} logo={lumiqWhiteLogoWatermark} />
      <Footer handleNext={() => goToNextStep()} />
    </Box>
  );
};

const Title = () => (
  <ModalTitle variant="light">You&apos;re all set!</ModalTitle>
);
