import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { Button } from '@/components';
import { CanProvinces } from '@/constants';
import { uiActions } from '@/store';
import { formatCost } from '@/utils';

export const ConfirmSelection = ({
  taxRates,
  province,
  offer,
  setProvince,
  onConfirm,
  onClose,
}) => {
  const dispatch = useDispatch();

  if (!offer || !taxRates)
    return (
      <Box sx={{ height: 400, display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );

  const priceInCents = offer.price;
  const discountInCents = offer.discount;
  const subtotalInCents = offer.price;
  const taxRate = taxRates[province]?.percentage || 0;
  const taxInCents = offer.price * taxRate;
  const totalInCents = offer.price * (1 + taxRate);

  const handleProvinceChange = event => {
    setProvince(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(totalInCents);
  };

  const onCloseClick = () => {
    dispatch(uiActions.closeActiveModal());
    onClose();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant="h2" sx={{ textAlign: 'center', mt: 2 }}>
        Order Summary
      </Typography>
      <Select
        value={province}
        onChange={handleProvinceChange}
        displayEmpty
        sx={{ width: 240, mt: 2 }}>
        <MenuItem value={''} disabled>
          Select Location (required)
        </MenuItem>
        <MenuItem value={'NO_TAX'}>Outside Canada</MenuItem>
        {Object.entries(CanProvinces)
          .sort()
          .map(([key, label]) => (
            <MenuItem value={key} key={key}>
              {label}
            </MenuItem>
          ))}
      </Select>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Payment Summary
      </Typography>
      <Box>
        <Box sx={styles.paymentItemContainer}>
          <Typography>{offer.descriptiveName}</Typography>
          <Typography sx={{ ml: 'auto' }}>
            ${formatCost(priceInCents + (discountInCents ?? 0))}
          </Typography>
        </Box>
        {!!discountInCents && (
          <Box sx={styles.paymentItemContainer}>
            <Typography>Discount:</Typography>
            <Typography sx={{ ml: 'auto', color: 'green' }}>
              -${formatCost(discountInCents)}
            </Typography>
          </Box>
        )}
        <Box sx={styles.paymentItemContainer}>
          <Typography>Subtotal:</Typography>
          <Typography sx={{ ml: 'auto' }}>
            ${formatCost(subtotalInCents)}
          </Typography>
        </Box>
        <Box sx={styles.paymentItemContainer}>
          <Typography>Tax:</Typography>
          <Typography sx={{ ml: 'auto' }}>
            {`(${taxRate * 100}%)`} ${formatCost(taxInCents)}
          </Typography>
        </Box>
        <Box sx={styles.paymentItemContainer}>
          <Typography>Total:</Typography>
          <Typography sx={{ ml: 'auto' }}>
            <strong>${formatCost(totalInCents)}</strong>
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ mt: 2 }} variant="body2">
        {offer.finePrint}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography component="span" variant="body2">
          Questions?&nbsp;
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={styles.link}
          onClick={() => window.Intercom('showNewMessage')}>
          Reach us by clicking here.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDiretion: 'row',
          justifyContent: 'center',
          mt: 3,
        }}>
        <Box sx={{ mr: 2 }}>
          <Button
            label="Cancel"
            variant="outlined"
            fullWidth={false}
            onClick={onCloseClick}
          />
        </Box>
        <Button
          label="Next"
          fullWidth={false}
          onClick={handleConfirm}
          disabled={!province}
        />
      </Box>
    </Box>
  );
};

const styles = {
  paymentItemContainer: {
    display: 'flex',
    mt: 1,
  },
  discountItem: {
    ml: 'auto',
    color: 'green',
  },
  link: {
    color: 'blue',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};
