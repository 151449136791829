import { Done } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

import { roundFloat } from '@/utils';

import { ProgressRing } from './ProgressRing';

export const SummaryCredits = ({ credits, label, requirement }) => {
  const theme = useTheme();

  const percent = Math.floor(
    (Math.min(credits, requirement) / requirement) * 100,
  );
  const colour = percent < 100 ? theme.palette.teal : theme.palette.green;
  const fill =
    percent < 100 ? theme.palette.blueBlackLight : theme.palette.grayLight;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.chartContainer}>
        <Box sx={styles.progressRing}>
          <ProgressRing
            colour={colour}
            fill={fill}
            stroke={4}
            radius={32}
            percent={percent}
          />
          <Box sx={styles.progressRingContent}>
            <Done
              sx={{ fontSize: 36, color: percent < 100 ? 'grayLight' : 'teal' }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.labelContainer}>
        <Typography variant="select" sx={styles.label}>
          {label}
        </Typography>
        <Box>
          <Typography component={'span'} sx={styles.numerator}>
            {roundFloat(credits, 2)}
          </Typography>
          <Typography
            component={'span'}
            variant="select"
            sx={styles.denominator}>
            /{requirement}hrs
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: theme => ({
    alignItems: 'center',
    border: `solid 1px ${theme.palette.grayBrightLight}`,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    ml: 'auto',
    mr: {
      xs: 'auto',
      md: 0,
    },
    maxWidth: 280,
    p: 2,
  }),
  chartContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 64,
    width: 64,
    justifyContent: 'center',
  },
  progressRing: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  progressRingContent: {
    alignItems: 'center',
    display: 'flex',
    height: 64,
    width: 64,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
  },
  chartPercent: {
    color: 'blueBlack',
    fontFamily: 'Oswald',
    fontSize: 16,
    fontWeight: 'bold',
  },
  labelContainer: {
    flex: '0 0 auto',
    ml: 2,
    minWidth: 110,
  },
  label: {
    color: 'gray',
  },
  numerator: {
    color: 'blueBlack',
    fontFamily: 'Oswald',
    fontSize: 34,
    fontWeight: 'bold',
  },
  denominator: {
    color: 'gray',
    ml: 1,
  },
};
