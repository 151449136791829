import { CloudDownload } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { ButtonProgressSpinner } from '@/components';
import { TrackerMessages } from '@/constants';
import { useDownloadCertificateSummaryMutation } from '@/store';
import { SnackbarUtils } from '@/utils';

import { Certificate } from './Certificate';

export const Certificates = ({ credits = [], dateRange }) => {
  const [downloadCertificateSummary, { isLoading: isSummaryDownloading }] =
    useDownloadCertificateSummaryMutation();

  const onDownloadSummaryClick = async () => {
    if (isSummaryDownloading) return;
    const { error } = await downloadCertificateSummary({
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
    });
    if (error) SnackbarUtils.error(TrackerMessages.DOWNLOAD_ERROR);
  };
  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Typography variant="h2" sx={{ color: 'blueBlack' }}>
          Certificates
        </Typography>
        <IconButton
          disabled={!credits.length || isSummaryDownloading}
          onClick={onDownloadSummaryClick}>
          {isSummaryDownloading ? (
            <ButtonProgressSpinner />
          ) : (
            <CloudDownload
              sx={{
                color: 'blueBlack',
                fontSize: 20,
              }}
            />
          )}
        </IconButton>
      </Box>
      {!credits.length && (
        <Typography variant="subtitle1" sx={{ color: 'gray' }}>
          Don't fall into arrears on your CPD, go get some!
        </Typography>
      )}
      {credits.map(credit => (
        <Certificate key={credit.creditId} credit={credit} />
      ))}
    </Box>
  );
};

const styles = {
  root: {
    border: theme => `solid 1px ${theme.palette.grayBrightLight}`,
    borderRadius: 2,
    boxShadow: theme => `0 2px 5px 0 ${theme.palette.grayBrightLight}`,
    boxSizing: 'border-box',
    p: 3,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
};
