import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AnalyticsService } from '@/services';
import {
  selectSessionUserId,
  selectUserRegion,
  useFetchPlanQuery,
  useFetchPlayerConfigQuery,
  useFetchUserProfileQuery,
  useFetchUserTagsQuery,
} from '@/store';

export const MixPanel = () => {
  const [pageLoaded, setLoaded] = useState(false);
  const location = useLocation();
  const userRegion = useSelector(selectUserRegion);
  const userId = useSelector(selectSessionUserId);
  const userQueryArg = userId ? undefined : skipToken;

  const { data: userProfile, isLoading: profileLoading } =
    useFetchUserProfileQuery(userQueryArg);
  const { data: userTags, isLoading: tagsLoading } =
    useFetchUserTagsQuery(userQueryArg);
  const { data: plan, isLoading: isPlanLoading } =
    useFetchPlanQuery(userQueryArg);
  const { data: playerConfig = {}, isLoading: isPlayerConfigLoading } =
    useFetchPlayerConfigQuery();

  const isLoading =
    profileLoading || tagsLoading || isPlanLoading || isPlayerConfigLoading;

  useEffect(() => {
    AnalyticsService.initialize({
      userId,
      email: userProfile?.email,
      userRegion,
      name: userProfile
        ? `${userProfile?.firstName} ${userProfile?.lastName}`
        : null,
      organizationName: plan?.organizationName,
      ...Object.fromEntries(
        Object.entries(playerConfig).map(([key, value]) => [
          `player_${key}`,
          value,
        ]),
      ),
    });
    AnalyticsService.updateUserTags(userTags);

    if (!pageLoaded && !isLoading) {
      const queryParams = Object.fromEntries(
        new URLSearchParams(location.search),
      );
      const { campaign_id: campaignId } = queryParams;
      AnalyticsService.pageLoad({ queryParams, campaignId });
      setLoaded(true);
    }
  }, [
    userProfile,
    userId,
    userRegion,
    userTags,
    playerConfig,
    location.search,
    isLoading,
    pageLoaded,
    plan,
  ]);

  return null;
};
