import { Box, CircularProgress } from '@mui/material';

export const DetailBox = ({ title, count, img, isLoading }) => (
  <Box sx={styles.detailBoxContainer}>
    <Box component="img" alt="" sx={styles.detailBoxImg} src={img} />
    <Box sx={styles.detailBox}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={styles.detailBoxCount}>{count}</Box>
          <Box sx={styles.detailBoxTitle}>{title}</Box>
        </>
      )}
    </Box>
  </Box>
);

const styles = {
  detailBoxContainer: theme => ({
    position: 'relative',
    display: 'flex',
    width: 230,
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(170),
    },
  }),
  detailBox: theme => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    boxShadow: `0px 4px 8px ${theme.palette.blueBlack}33`,
    borderRadius: 1.5,
    ml: { xs: 3, sm: 6 },
    pl: 5,
    width: 200,
    height: 120,
    [theme.breakpoints.down('sm')]: {
      height: 84,
      width: 140,
    },
  }),
  detailBoxImg: theme => ({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 25,
    height: 80,
    width: 80,
    [theme.breakpoints.down('sm')]: {
      top: 12,
      height: 60,
      width: 60,
    },
  }),
  detailBoxCount: theme => ({
    ...theme.typography.infoBox,
    lineHeight: 1,
  }),
  detailBoxTitle: theme => ({
    ...theme.typography.subtitle3,
    color: 'gray',
  }),
};
