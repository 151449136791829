import { Box, Typography } from '@mui/material';

import { roundFloat } from '@/utils';

export const OverviewSummary = ({ totalCredits, yearlyRequirement }) => {
  return (
    <Box>
      <Typography variant="select" sx={{ color: 'gray' }}>
        You have
      </Typography>
      <Box>
        <Typography component={'span'} sx={styles.numerator}>
          {roundFloat(totalCredits, 2)}
        </Typography>
        <Typography variant="select" sx={styles.denominator}>
          {yearlyRequirement ? `/${yearlyRequirement}hrs` : 'hrs'}
        </Typography>
      </Box>
      {!!yearlyRequirement && (
        <Typography sx={styles.message}>
          {getMessage({ totalCredits, yearlyRequirement })}
        </Typography>
      )}
    </Box>
  );
};

function getMessage({ totalCredits, yearlyRequirement }) {
  if (!totalCredits || totalCredits <= 0) {
    return "You haven't completed any hours yet, go and listen to a few episodes!";
  }
  if (totalCredits < 0.25 * yearlyRequirement) {
    return 'Good job on starting, keep it going!';
  }
  if (totalCredits < 0.4 * yearlyRequirement) {
    return "You're a quarter of the way there, you are off to a great start!";
  }
  if (totalCredits < 0.5 * yearlyRequirement) {
    return 'Look at you go, you are almost halfway through your hours!';
  }
  if (totalCredits < 0.75 * yearlyRequirement) {
    return 'Keep it going, you are past the halfway mark for your hours!';
  }
  if (totalCredits < yearlyRequirement) {
    return (
      `In the home stretch! Only ${roundFloat(yearlyRequirement - totalCredits)} more hours ` +
      'until you are done with your yearly requirements!'
    );
  }
  return 'Nice work! You are fully compliant for this regulatory period.';
}

const styles = {
  numerator: {
    color: 'blueBlack',
    fontFamily: 'Oswald',
    fontSize: 72,
    lineHeight: 1,
    fontWeight: 'bold',
  },
  denominator: {
    color: 'gray',
    ml: 1,
  },
  message: theme => ({
    ...theme.typography.select,
    color: 'gray',
    mt: 2,
  }),
};
