import { Box, CircularProgress } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { RoutesWithPlayer } from '@/constants';
import {
  AppErrorBoundary,
  AppModals,
  AppRouteWrapper,
  Player,
  ToolWrapper,
} from '@/containers';
import { AnalyticsService } from '@/services';
import {
  selectIsFsmPlayer,
  selectSessionUserId,
  useFetchPlanQuery,
  useFetchPlayerConfigQuery,
  useFetchPlayerSessionsQuery,
  useFetchUserEpisodesQuery,
  useFetchUserProfileQuery,
  useFetchUserRegionQuery,
  useFetchUserRegistrationStateQuery,
  useFetchUserTagsQuery,
  useLinkPlanToUserMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const AppLoader = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);
  const userQueryArg = userId ? undefined : skipToken;

  const [isSentryInitialized, setIsSentryInitialized] = useState(false);

  const {
    data: profile,
    isLoading: isUserProfileLoading,
    error: fetchUserProfileError,
  } = useFetchUserProfileQuery(userQueryArg);

  const {
    data: plan,
    isLoading: isPlanLoading,
    error: planError,
  } = useFetchPlanQuery(userQueryArg);

  const {
    data: region,
    isLoading: isUserRegionLoading,
    error: userRegionError,
  } = useFetchUserRegionQuery(userQueryArg);

  const { isLoading: isUserTagsLoading, error: userTagsError } =
    useFetchUserTagsQuery(userQueryArg);
  const { isLoading: isPlayerConfigLoading, error: playerConfigError } =
    useFetchPlayerConfigQuery();

  const isFsmPlayer = useSelector(selectIsFsmPlayer);

  // TODO discuss dispatching a thunk to deal with all app load data
  // and then using selectors for things like userEpisodes which we know are App loaded
  // and guaranteed to be there
  const { isLoading: isPlayerSessionsLoading, error: errorOld } =
    useFetchPlayerSessionsQuery(userId && isFsmPlayer ? undefined : skipToken);
  const { isLoading: isLegacyUserEpisodesLoading, error: errorNew } =
    useFetchUserEpisodesQuery(userId && !isFsmPlayer ? undefined : skipToken);
  const isUserEpisodesLoading = isFsmPlayer
    ? isPlayerSessionsLoading
    : isLegacyUserEpisodesLoading;
  const userEpisodesError = isFsmPlayer ? errorNew : errorOld;

  const {
    data: registrationState,
    isLoading: isUserRegistrationStateLoading,
    error: userRegistrationStateError,
  } = useFetchUserRegistrationStateQuery(userQueryArg);

  const [linkPlanToUser] = useLinkPlanToUserMutation();

  const { pathname, state } = useLocation();

  const isPlayerRoute = useMemo(() => {
    return Object.values(RoutesWithPlayer).some(route =>
      matchPath(route, pathname),
    );
  }, [pathname]);

  useEffect(() => {
    const referrer = state?.referrer;
    if (referrer) {
      const [section, resourceId] = pathname?.split('/').slice(1) || [];
      AnalyticsService.clickThrough({
        target: pathname,
        referrer,
        section,
        resourceId,
      });
    }

    AnalyticsService.pageView(state);
  }, [pathname, state]);

  const isAppLoading =
    isUserProfileLoading ||
    isUserEpisodesLoading ||
    isPlanLoading ||
    isUserRegistrationStateLoading ||
    isUserRegionLoading ||
    isUserTagsLoading ||
    isPlayerConfigLoading;

  const error =
    fetchUserProfileError ||
    planError ||
    userEpisodesError ||
    userRegistrationStateError ||
    userRegionError ||
    userTagsError ||
    playerConfigError;

  useEffect(() => {
    if (isAppLoading) return;

    if (error) {
      Sentry.setUser({ id: userId });
    } else {
      Sentry.setUser({ id: userId, email: profile?.email });
      Sentry.setContext('plan', plan);
      Sentry.setContext('user info', {
        region,
        registrationState,
        ...profile,
      });
    }

    setIsSentryInitialized(true);
  }, [error, isAppLoading, userId, profile, plan, region, registrationState]);

  useEffect(() => {
    if (isAppLoading) return;

    const linkPlan = async props => {
      const { error } = await linkPlanToUser(props);
      if (error) {
        SnackbarUtils.error('Failed to accept invite. Please contact support.');
        return;
      }

      const url = new URL(window.location.href);
      url.searchParams.delete('inviteToken');
      window.history.replaceState({}, '', url);
    };

    const query = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );
    const { inviteToken } = query;

    if (inviteToken && userId) {
      linkPlan({ inviteToken });
    }
  }, [dispatch, linkPlanToUser, userId, isAppLoading]);

  return (
    <Fragment>
      {(isAppLoading || !isSentryInitialized) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
          <CircularProgress />
        </Box>
      )}
      {!isAppLoading && error && <AppErrorBoundary />}
      {!isAppLoading && !error && isSentryInitialized && (
        <Fragment>
          <AppRouteWrapper />
          {isPlayerRoute && <Player />}
          <AppModals />
          <ToolWrapper />
        </Fragment>
      )}
    </Fragment>
  );
};
