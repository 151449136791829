import { Close } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Modals, urls } from '@/constants';
import { AppModal } from '@/containers';
import { AnalyticsService, CPA_BUTTON_NAME } from '@/services';
import { selectSessionUserId, uiActions } from '@/store';

export const CPAContentEndModal = ({ cpaCanadaContent }) => {
  const dispatch = useDispatch();
  const isAnonymous = !useSelector(selectSessionUserId);
  const onCloseModal = () => {
    AnalyticsService.closeModal(
      isAnonymous ? Modals.CPA_SIGNUP : Modals.CPA_LISTEN_VERIFIABLE,
    );
    dispatch(uiActions.closeActiveModal());
  };

  if (!cpaCanadaContent) return null;

  return (
    <AppModal
      fullWidth
      maxWidth="lg"
      modalName={Modals.CPA_CONTENT_END}
      disableBackdropClick>
      <Box sx={styles.close}>
        <Close
          style={{ color: 'black' }}
          fontSize="large"
          onClick={onCloseModal}
        />
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.left}>
          <Box
            sx={styles.groupImage}
            src={cpaCanadaContent.modalEpStart.logo}
            alt=""
            component="img"
          />
        </Box>
        <Box sx={styles.right}>
          <Typography variant="h1">
            {cpaCanadaContent.modalEpFinished.header}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
            {cpaCanadaContent.modalEpFinished.subHeader}
          </Typography>
          <Box sx={styles.buttons}>
            <Button
              onClick={() => {
                AnalyticsService.buttonClick(CPA_BUTTON_NAME.CONTENT_END_MODAL);

                window.open(
                  urls.accounts.root + cpaCanadaContent.modalEpFinished.ctaLink,
                  '_self',
                );
              }}
              sx={styles.callToAction}>
              {cpaCanadaContent.modalEpFinished.ctaBtnText}
            </Button>
            {isAnonymous && (
              <Typography sx={styles.bottomText}>
                {cpaCanadaContent.modalEpFinished.footer}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </AppModal>
  );
};

const styles = {
  container: theme => ({
    display: 'flex',
    flexDirection: 'row',
    height: theme.typography.pxToRem(394),
    width: '100%',
    backgroundColor: 'grayNeutralLight',
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(360),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: theme.typography.pxToRem(690),
    },
  }),
  close: theme => ({
    position: 'absolute',
    top: 30,
    left: 30,
    color: 'gray',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      top: 10,
      left: 10,
    },
  }),
  perkContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  bottomText: theme => ({
    ...theme.typography.subtitle1,
    mt: 1,
    color: '#background: rgba(51, 50, 21, 1)',
    fontStyle: 'italic',
  }),
  left: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    flex: 3,
    m: 2,
  },
  groupImage: theme => ({
    width: theme.typography.pxToRem(360),
    height: theme.typography.pxToRem(360),
    [theme.breakpoints.down('md')]: {
      width: theme.typography.pxToRem(300),
      height: theme.typography.pxToRem(300),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(312),
      height: theme.typography.pxToRem(312),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.typography.pxToRem(228),
      height: theme.typography.pxToRem(228),
    },
  }),
  buttons: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  callToAction: {
    backgroundColor: 'rgba(38, 50, 56, 1)',
    color: 'white',
    borderRadius: 40,
    textTransform: 'none',
    width: '100%',
    mt: 1,
    mb: 1,
    '&:hover': {
      color: 'rgba(38, 50, 56, 1)',
    },
  },
};
