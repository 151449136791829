import { CheckRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { getRedirectUrl, Modals, urls } from '@/constants';
import { AnalyticsService, EVENTS } from '@/services';
import {
  getDisablePurchasing,
  selectSessionUserId,
  uiActions,
  useGetMyOffersQuery,
} from '@/store';

import { PersonalOfferPlaceholder } from './PersonalOfferPlaceholder';

export const PersonalOffer = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);
  const disablePurchasing = useSelector(getDisablePurchasing);
  const { data: userOffer, isLoading: isUserOffersLoading } =
    useGetMyOffersQuery(undefined, {
      skip: !userId,
    });

  const onClickPurchase = () => {
    if (!userId) {
      window.open(getRedirectUrl(urls.accounts.login), '_self');
    } else {
      AnalyticsService.logEvent(EVENTS.PLAN_EVENTS.CLICK_BUY, {
        userId: userId,
        userOffer: userOffer,
      });
      dispatch(
        uiActions.setActiveModal({
          name: Modals.PURCHASE,
          params: { offer: userOffer },
        }),
      );
    }
  };

  return isUserOffersLoading ? (
    <PersonalOfferPlaceholder />
  ) : !!userOffer ? (
    <Box
      sx={{
        maxWidth: { xs: 311, sm: 646 },
        borderRadius: 4,
        boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        bgcolor: 'white',
      }}>
      <Box
        sx={{
          width: '100%',
          height: 48,
          background: theme => theme.gradients.primary,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="h3" color="white">
          Special Offer
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              m: 3,
            }}>
            <Typography
              variant="infoBox"
              sx={{
                textDecoration: 'line-through',
                color: 'blueBlack',
              }}>
              ${(userOffer.discount + userOffer.price).toFixed(2)}
            </Typography>
            <Typography variant="infoBox" color="blueBlack">
              ${userOffer.price.toFixed(2)}
            </Typography>
            <Box
              sx={{
                p: '13px 15px',
                bgcolor: 'grayLight',
                borderRadius: 2,
                border: theme => `1px solid ${theme.palette.grayBrightMid}`,
                mt: 3,
                maxWidth: 200,
              }}>
              <Typography variant="subtitle1">{userOffer.name}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              m: 3,
            }}>
            <Typography variant="subtitle1" color="blueBlack">
              {userOffer.description}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                mt: 2,
                gap: 1,
              }}>
              {userOffer.bullets.map((bullet, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', flexDirection: 'row', gap: 0.25 }}>
                  <CheckRounded />
                  <Typography variant="select">{bullet}</Typography>
                </Box>
              ))}
            </Box>
            <Box mt={3} width={200}>
              <Button
                label={userOffer.cta}
                variant="primary"
                onClick={onClickPurchase}
                disabled={disablePurchasing}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};
