import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { PlayerConfigs } from '@/constants';

import { ConfigResponse } from '../player-fsm/player-fsm.types';
import { playerFsmApi } from '../player-fsm-api';
import { selectSessionUserId } from '../session';
import { RootState } from '../store';
import { selectUserRegionResult } from '../user-api';
import { playerApi } from './player.slice';

export const selectIsPlayerPlaying = (state: RootState) =>
  state.player.isPlaying;

export const selectActiveChapterId = (state: RootState) =>
  state.player.activeChapter?.chapterId;

export const selectActiveEpisodeId = (state: RootState) =>
  state.player.activeChapter?.episodeId;

/** Only available for new Player Session FSM */
export const selectActiveSessionId = (state: RootState) =>
  state.player.activeChapter?.sessionId;

export const selectPlayerProgress = (state: RootState) => state.player.progress;

export const selectPlayerSeekToPosition = (state: RootState) =>
  state.player.seekToPosition;

export const selectIsSavingProgress = (state: RootState) =>
  state.player.isSavingProgress;

export const selectLastSavedProgress = (state: RootState) =>
  state.player.lastSavedProgress;

export const selectIsPlayerSeeking = (state: RootState) =>
  state.player.isSeeking;

export const selectPlayerVolume = (state: RootState) => state.player.volume;

export const selectPlayerSpeed = (state: RootState) => state.player.speed;

export const selectPlayerAutoSaveInterval = (state: RootState) =>
  state.player.autoSaveInterval;

export const selectIsPlayerBuffering = (state: RootState) =>
  (state.player.isBuffering || !state.player.isTrackLoaded) &&
  state.player.isPlaying;

export const selectIsPlaying = (state: RootState) => state.player.isPlaying;

export const selectIsPlayerVideoFullscreen = (state: RootState) =>
  state.player.isVideoFullscreen;

export const selectIsPlayerExpanded = (state: RootState) =>
  state.player.isExpanded;

export const selectPlayerOnSeekMessage = (state: RootState) =>
  state.player.messages.onSeek;

export const selectPlayerLocationReferrer = (state: RootState) =>
  R.pick(['location', 'referrer'], state.player);

export const selectIsTrackLoaded = (state: RootState) =>
  state.player.isTrackLoaded;

export const selectIsChangingChapter = (state: RootState) =>
  state.player.isChangingChapter || state.player.isAttemptingToChangeChapter;

export const selectIsPlaybackRejected = (state: RootState) =>
  state.player.isPlaybackRejected;

export const selectPlaybackRejectedChapter = (state: RootState) =>
  state.player.playbackRejectedChapter;

const selectPlayerConfigResult =
  playerFsmApi.endpoints.fetchPlayerConfig.select(undefined);

export const selectIsFsmPlayer = createSelector(
  selectSessionUserId,
  selectUserRegionResult,
  selectPlayerConfigResult,
  (userId, userRegionResult, playerConfigResult) => {
    if (!userId) return false;
    if (
      userRegionResult.isLoading ||
      userRegionResult.isUninitialized ||
      playerConfigResult.isLoading ||
      playerConfigResult.isUninitialized
    )
      return undefined;

    if (
      playerConfigResult.data === undefined ||
      userRegionResult.data === undefined
    ) {
      return false;
    }
    return (
      playerConfigResult.data[userRegionResult.data as keyof ConfigResponse] ===
      PlayerConfigs.SESSION_MODEL
    );
  },
);

export const selectFetchPlaybackSpeedOptionsResult =
  playerApi.endpoints.fetchPlaybackSpeedOptions.select(undefined);

export const selectPlaybackSpeedOptons = createSelector(
  selectIsFsmPlayer,
  selectFetchPlaybackSpeedOptionsResult,
  (state: RootState) => state.player.playbackSpeedOptions,
  (
    isFsmPlayer,
    fetchPlaybackSpeedOptionsResult,
    fsmClientDirectivePlaybackSpeedOptions,
  ) => {
    return isFsmPlayer
      ? fsmClientDirectivePlaybackSpeedOptions
      : fetchPlaybackSpeedOptionsResult?.data;
  },
);
