import { Share, UnfoldLess } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsService } from '@/services';
import {
  playerActions,
  selectActiveEpisodeId,
  selectUserRegion,
  useFetchEpisodeQuery,
  useShowProductFeedbackIfReadyMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const TopControls = () => {
  const dispatch = useDispatch();

  const region = useSelector(selectUserRegion);
  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const { data: episode } = useFetchEpisodeQuery({
    episodeId: activeEpisodeId,
    region,
  });

  const [showProductFeedbackIfReady] = useShowProductFeedbackIfReadyMutation();

  const onMinimize = () => {
    dispatch(playerActions.setExpanded(false));
  };

  return (
    <Box sx={styles.buttonContainer}>
      {episode && (
        <Button
          sx={styles.button}
          onClick={() => {
            navigator.clipboard.writeText(episode?.deepLink);
            AnalyticsService.episodeShare({ episode });
            SnackbarUtils.success('Link copied to clipboard');
            showProductFeedbackIfReady();
          }}>
          <Share />
          Share <Hidden mdDown>Episode</Hidden>
        </Button>
      )}
      <Button sx={styles.button} onClick={onMinimize}>
        <UnfoldLess />
        Minimize
      </Button>
    </Box>
  );
};

const styles = {
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mb: theme => theme.typography.pxToRem(14),
  },
  button: theme => ({
    fontSize: theme.typography.pxToRem(16),
    padding: `0 ${theme.typography.pxToRem(16)}`,
    fontWeight: 400,
    '> * + *': {
      ml: theme => theme.typography.pxToRem(8),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  }),
};
