import { Cancel, Close, Image } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppButton, Button } from '@/components';
import { Modals, TrackerMessages } from '@/constants';
import {
  selectActiveModalParams,
  uiActions,
  useCreateCustomCPDRecordMutation,
  useUpdateCustomCPDRecordMutation,
} from '@/store';
import { displaySize, SnackbarUtils } from '@/utils';

import { validateCustomCPDHourRecord } from './utils';

const nextYear = new Date().getFullYear() + 1;

export const TrackerAddEditModalContent = () => {
  const dispatch = useDispatch();

  const { credit } = useSelector(selectActiveModalParams) || {};

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [createCustomCPDRecord, { isLoading: isCreateLoading }] =
    useCreateCustomCPDRecordMutation();

  const [updateCustomCPDRecord, { isLoading: isUpdateLoading }] =
    useUpdateCustomCPDRecordMutation();

  const isLoading = isCreateLoading || isUpdateLoading;

  useEffect(() => {
    if (!credit) return;
    const file = credit.fileName && {
      name: credit.fileName,
      size: credit.fileSize,
      fileUri: credit.fileUri,
    };
    setValues({
      id: credit.id,
      dateCompleted: new Date(credit.dateCompleted),
      description: credit.description,
      file,
      credit: credit.credit,
      isEthics: !!credit.ethicsCredit,
      origFile: credit.fileName && {
        name: credit.fileName,
        size: credit.fileSize,
        fileUri: credit.fileUri,
      },
      source: credit.source,
    });
  }, [credit]);

  useEffect(() => {
    // Validate form on every change after the first submit attempt
    if (!attemptedSubmit) return;
    const newErrors = validateCustomCPDHourRecord(values);
    setErrors(newErrors);
  }, [values, attemptedSubmit]);

  const onSubmitCreate = async () => {
    const newErrors = validateCustomCPDHourRecord(values);
    if (Object.keys(newErrors).length) {
      setAttemptedSubmit(true);
      return;
    }
    const { error } = await createCustomCPDRecord(values);
    if (error) {
      SnackbarUtils.error(TrackerMessages.CREATE_CUSTOM_CPD_ERROR);
      return;
    }
    SnackbarUtils.success(TrackerMessages.CREATE_CUSTOM_CPD_SUCCESS);
    onClose();
  };

  const onSubmitUpdate = async () => {
    const newErrors = validateCustomCPDHourRecord(values);
    if (Object.keys(newErrors).length) {
      setAttemptedSubmit(true);
      return;
    }
    const { error } = await updateCustomCPDRecord({
      newPdHour: values,
      pdHourId: credit.creditId,
    });
    if (error) {
      SnackbarUtils.error(TrackerMessages.UPDATE_CUSTOM_CPD_ERROR);
      return;
    }
    SnackbarUtils.success(TrackerMessages.UPDATE_CUSTOM_CPD_SUCCESS);
    onClose();
  };

  const onClose = () => {
    dispatch(uiActions.closeActiveModal());
  };

  const onDelete = () => {
    dispatch(uiActions.closeActiveModal());
    dispatch(
      uiActions.setActiveModal({
        name: Modals.TRACKER_DELETE,
        params: { credit },
      }),
    );
  };

  const isEditMode = !!credit;

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={styles.titleContainer}>
            <Typography variant="h3">
              {isEditMode ? 'Edit Hours' : 'Add Hours'}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            name="source"
            label="Course/Seminar Title"
            type="text"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            value={values.source || ''}
            error={!!errors.source}
            disabled={isLoading}
            onChange={event =>
              setValues({ ...values, source: event.target.value })
            }
          />
          {!!errors.source && (
            <FormHelperText variant="filled" error>
              {errors.source}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            name="hours"
            label="CPD Hours"
            type="text"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            value={values.credit || ''}
            error={!!errors.credit}
            disabled={isLoading}
            onChange={event =>
              setValues({ ...values, credit: event.target.value })
            }
          />
          {!!errors.credit && (
            <FormHelperText variant="filled" error>
              {errors.credit}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={styles.dayPickerContainer}>
            <InputLabel
              sx={styles.dayPickerLabel}
              error={!!errors.dateCompleted}
              required
              shrink>
              Date
            </InputLabel>
            <DatePicker
              sx={{ width: '100%' }}
              minDate={new Date(2012, 8, 1)}
              maxDate={new Date(nextYear, 11, 31)}
              disabled={isLoading}
              value={values.dateCompleted || null}
              onChange={date =>
                setValues({
                  ...values,
                  dateCompleted: date,
                })
              }
            />
          </Box>
          {!!errors.dateCompleted && (
            <FormHelperText variant="filled" error>
              {errors.dateCompleted}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={[
              styles.ethicsField,
              values.isEthics && styles.ethicsFieldSelected,
            ]}>
            <Typography>Qualifies as Ethics</Typography>
            <Switch
              checked={!!values.isEthics}
              disabled={isLoading}
              onChange={() =>
                setValues({ ...values, isEthics: !values.isEthics })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            name="description"
            label="Course Description"
            type="text"
            InputLabelProps={{ shrink: true }}
            multiline
            rows={4}
            fullWidth
            value={values.description || ''}
            error={!!errors.description}
            disabled={isLoading}
            onChange={event =>
              setValues({ ...values, description: event.target.value })
            }
          />
          {!!errors.description && (
            <FormHelperText variant="filled" error>
              {errors.description}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.uploadTitle}>
            <b>Upload Certificate</b> (optional)
          </Typography>
          <Typography sx={styles.uploadSubtitle}>
            You can upload a photo of a certificate, attendance sheet or any
            other document that verifies you completed your CPD hours.
          </Typography>
          <Box sx={styles.uploadContainer}>
            <Box sx={styles.uploadButton}>
              <AppButton
                component="label"
                startIcon={<Image />}
                disabled={isLoading}>
                Upload file
                <input
                  hidden
                  type="file"
                  onChange={event => {
                    const { target } = event;
                    setValues({ ...values, file: target.files[0] });
                    // TODO: is this comment still relevant?
                    // reset input to allow same file to be selected again in case removed
                    // fixes a really small edge case if user selects the file, removes it,
                    // then selects it again, where it wasn't showing in the form!
                    // https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
                    target.value = null;
                  }}
                />
              </AppButton>
            </Box>
            {!!values.file && (
              <>
                <Typography sx={styles.fileName}>{values.file.name}</Typography>
                <Typography variant="pill2" sx={styles.fileInfoSize}>
                  {displaySize(values.file.size)}
                </Typography>
                <IconButton
                  size="small"
                  disabled={isLoading}
                  onClick={() => setValues({ ...values, file: null })}>
                  <Cancel sx={styles.cancelIcon} />
                </IconButton>
              </>
            )}
            {!values.file && (
              <Typography variant="select" sx={styles.fileInfoDescription}>
                No file selected yet (Max file size 10MB)
              </Typography>
            )}
          </Box>
          {!!errors.file && (
            <FormHelperText error>{errors.file}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={styles.buttonContainer}>
            {isEditMode ? (
              <>
                <Box sx={{ mr: 2 }}>
                  <Button
                    label="Delete"
                    onClick={onDelete}
                    variant="warning"
                    fullWidth={false}
                    disabled={isLoading}
                  />
                </Box>
                <Button
                  label="Save"
                  onClick={onSubmitUpdate}
                  variant="primary"
                  fullWidth={false}
                  disabled={isLoading}
                />
              </>
            ) : (
              <Button
                label="Submit"
                onClick={onSubmitCreate}
                variant="primary"
                fullWidth={false}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={15} />}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dayPickerContainer: {
    position: 'relative',
  },
  dayPickerLabel: {
    backgroundColor: 'white',
    left: 10,
    px: 1,
    position: 'absolute',
    top: -7,
    zIndex: 10,
  },
  dayPickerIcon: {
    color: 'blueBlack',
    fontSize: 24,
    pointerEvents: 'none',
    position: 'absolute',
    right: 16,
    top: 16,
  },
  dayPickerError: {
    borderColor: 'red',
  },
  ethicsField: {
    alignItems: 'center',
    border: '1px solid #C4C4C4',
    borderRadius: 1,
    display: 'flex',
    height: 56,
    justifyContent: 'space-between',
    py: 1,
    px: 2,
    width: '100%',
  },
  ethicsFieldSelected: {
    border: theme => `1px solid ${theme.palette.green}`,
    backgroundColor: 'greenLight',
  },
  uploadTitle: {
    color: 'blueBlack',
    mb: 1,
  },
  uploadSubtitle: {
    color: 'blueBlack',
  },
  uploadWarning: {
    color: 'red',
  },
  uploadContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    mt: 2.5,
  },
  uploadButton: {
    mr: 2,
  },
  buttonContainer: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  fileInfo: {
    color: 'blueBlack',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileName: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  fileInfoSize: {
    color: 'gray',
    mx: 1,
    textWrap: 'nowrap',
  },
  fileInfoDescription: {
    color: 'gray',
  },
  cancelIcon: {
    color: 'red',
    fontSize: 24,
  },
  button: {
    height: 50,
    maxWidth: 180,
    width: '100%',
    '&:not(:last-child)': {
      mr: 3,
    },
  },
  switch: {
    '&$checked': {
      color: 'green',
    },
    '&$checked + $track': {
      backgroundColor: 'green',
    },
  },
};
