import { ChevronRight, CloudDownload } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { Link } from '@/containers';

export const Resources = ({ resources, variant }) => {
  return (
    <Box mt={2}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="h6"
          sx={{
            color: variant === 'player' ? 'white' : 'gray',
          }}>
          Download Files
        </Typography>
        <CloudDownload
          sx={{ ml: 1, color: variant === 'player' ? 'white' : 'gray' }}
        />
      </Box>
      {resources.map((rs, idx) => (
        <Box key={idx} mt={1} mb={1}>
          <Link to={rs.file.url} target="_blank">
            <Box sx={{ display: 'flex' }}>
              {idx + 1}. {rs.name}
              <ChevronRight />
            </Box>
          </Link>
        </Box>
      ))}
    </Box>
  );
};
