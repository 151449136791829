import { Cancel, Check } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { FreeTrialDefaults, getRedirectUrl, Modals, urls } from '@/constants';
import { groupPlayer } from '@/images';
import { selectUserRegion, uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const SignupModal = () => {
  const dispatch = useDispatch();
  const userRegion = useSelector(selectUserRegion);
  const isNasba = userRegion === 'USA';

  const CP = isNasba ? 'CPE' : 'CPD';
  const defaultCPValue =
    (isNasba ? FreeTrialDefaults?.CREDITS : FreeTrialDefaults?.HOURS) || 1;
  const defaultCPUnit = `${isNasba ? 'credit' : 'hour'}${defaultCPValue === 1 ? '' : 's'}`;

  return (
    <AppModal modalName={Modals.SIGN_UP} disableBackdropClick>
      <Box sx={styles.close}>
        <Cancel
          onClick={() => {
            dispatch(uiActions.closeActiveModal());
          }}
        />
      </Box>
      <Box sx={styles.container}>
        <Typography sx={styles.header}>
          Create an account to use this feature
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          Create a free account or login to use this feature,
          <br />
          no credit card required!
        </Typography>
        <Box sx={styles.featurePerksContainer}>
          <Box
            sx={styles.groupImage}
            component="img"
            src={groupPlayer}
            alt={`multiple devices for ${CP}`}
          />
          <Box sx={styles.perkRow}>
            {[
              'Bookmark Episodes',
              'Listen Offline',
              `${CP} Tracker`,
              'And Much More!',
            ].map(perk => (
              <Box sx={styles.featurePerk} key={perk}>
                <Check sx={styles.perkCheck} />
                <Typography sx={styles.perkInfo}>{perk}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Typography sx={styles.newAccountText}>
          With your new account you get {defaultCPValue} {defaultCPUnit} of
          <br />
          Verifiable {CP} for <b>FREE!</b>
        </Typography>
        <Box sx={styles.separator} />
        <Box sx={styles.buttons}>
          <Button
            href={getRedirectUrl(urls.accounts.login)}
            sx={[styles.loginButton, styles.button]}>
            Login
          </Button>
          <Button
            href={getRedirectUrl(urls.accounts.register)}
            sx={[styles.createAccount, styles.button]}>
            Create Account
          </Button>
        </Box>
      </Box>
    </AppModal>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 5.5,
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    p: 2.5,
    color: 'gray',
    cursor: 'pointer',
  },
  header: theme => ({
    ...theme.typography.h2,
    color: 'gray',
    mb: 1.5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  }),
  subtext: theme => ({
    ...theme.typography.body3,
    color: 'gray',
  }),
  perkRoot: {
    width: '100%',
    mb: 3,
  },
  featurePerk: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    my: {
      sm: 1.5,
    },
  },
  perkCheck: {
    height: theme => theme.typography.pxToRem(24),
    mr: 1.5,
  },
  perkInfo: theme => ({
    ...theme.typography.subtitle2,
    color: 'gray',
  }),
  groupImage: theme => ({
    width: theme.typography.pxToRem(200),
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(100),
      height: theme.typography.pxToRem(100),
      alignSelf: 'center',
    },
  }),
  perkRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  newAccountText: theme => ({
    ...theme.typography.body1,
    color: 'gray',
    textAlign: 'center',
  }),
  featurePerksContainer: {
    display: 'flex',
    my: 4,
  },
  separator: {
    height: '2px',
    width: '100%',
    backgroundColor: 'grayLight',
    my: 4,
  },
  buttons: {
    display: 'flex',
    width: { sm: '100%' },
    flexWrap: 'wrap',
    gap: 1,
  },
  button: theme => ({
    ...theme.button1,
    flex: 1,
    height: theme => theme.typography.pxToRem(50),
    minWidth: theme => theme.typography.pxToRem(180),
    borderRadius: theme => theme.typography.pxToRem(5),
  }),
  loginButton: {
    backgroundColor: 'tealLight',
    color: 'teal',
  },
  createAccount: {
    backgroundColor: 'teal',
    color: 'tealLight',
    ':hover': {
      color: 'teal',
    },
  },
};
