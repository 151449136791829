import * as R from 'ramda';

import { ChapterStatus, EpisodeStatus, FsmChapterStatus } from '@/constants';
import {
  FsmChapterStatus as FsmChapterStatusType,
  UserChapterProgress,
  UserEpisodeFsm,
} from '@/types';
import { camelCaseKeys, convertHHMMSStoSeconds } from '@/utils';

import {
  PlayerSessionResponse,
  PlayerSessionTransitionResponse,
  SessionChapter,
} from '../player-fsm/player-fsm.types';

export const transformSessionChapter = (
  chapter: SessionChapter,
): UserChapterProgress => ({
  chapterId: chapter.chapterId,
  id: chapter.chapterId,
  status: mapPlayerSessionChapterToLegacyStates(chapter.state),
  lastPlayed: chapter.lastEvent,
  completed: [
    FsmChapterStatus.QUIZ_READY,
    FsmChapterStatus.VERIFIED,
    FsmChapterStatus.COMPLETED,
  ].some(s => s === chapter.state),
  progress: {
    seekableDuration: chapter.maxPosition ?? 0,
    playableDuration: chapter.duration,
    currentTime: chapter?.position ?? 0,
    playedDuration: chapter.maxPosition ?? 0,
  },
  isVerifiable: chapter.class.includes('verifiable'),
});

export const transformSessionChapters = (
  chapters: SessionChapter[],
): UserChapterProgress[] => {
  return R.map(transformSessionChapter, chapters);
};

export const transformSessionResponse = (
  sessionResponse: any,
): PlayerSessionResponse => {
  return R.pipe(camelCaseKeys, response => ({
    ...response,
    state: {
      ...response.state,
      chapters: transformSessionChapters(response.state.chapters),
    },
  }))(sessionResponse);
};

export const transformSessionsResponse = (
  sessionsResponse: any[],
): UserEpisodeFsm[] => {
  return sessionsResponse.map((e: any) => ({
    lastPlayed: e.last_position_event_at,
    episodeId: e.episode_id,
    sessionId: e.session_id,
    // in old format but not returned now and not used
    isBookmarked: false,
    bookmarkedAt: null,
    status: calculateEpisodeState(e),
    episodeDuration: e.episode_length_seconds,
    playedDuration: convertHHMMSStoSeconds(e.played_duration),
  }));
};

export const calculateEpisodeState = (e: {
  chapters: { class: string[]; state: FsmChapterStatusType }[];
}) => {
  const verifiableChapters = e.chapters.filter(c =>
    c.class.includes('verifiable'),
  );
  const nonVerifiableChapters = e.chapters.filter(
    c => !c.class.includes('verifiable'),
  );

  if (
    !verifiableChapters.length &&
    nonVerifiableChapters.every(c => c.state === FsmChapterStatus.COMPLETED)
  )
    return EpisodeStatus.COMPLETED;

  if (
    !verifiableChapters.length &&
    nonVerifiableChapters.some(c => c.state === FsmChapterStatus.READY)
  )
    return EpisodeStatus.NONE;

  if (verifiableChapters.every(c => c.state === FsmChapterStatus.VERIFIED))
    return EpisodeStatus.VERIFIED;

  if (
    verifiableChapters.every(
      c =>
        c.state === FsmChapterStatus.VERIFIED ||
        c.state === FsmChapterStatus.QUIZ_READY,
    )
  )
    return EpisodeStatus.QUIZ_READY;

  if (verifiableChapters.every(c => c.state === FsmChapterStatus.READY))
    return EpisodeStatus.NONE;

  return EpisodeStatus.ON_GOING;
};

export const transformSessionResponseNullable = (
  sessionResponse: any,
): PlayerSessionResponse | null => {
  if (sessionResponse === null) {
    return null;
  }
  return transformSessionResponse(sessionResponse);
};

export const transformPlayerTransitionResponse = (
  playSessionResponse: any,
): PlayerSessionTransitionResponse => {
  return R.pipe(camelCaseKeys, response => ({
    ...response,
    state: {
      ...response.state,
      newState: {
        ...response.state.newState,
        chapters: transformSessionChapters(response.state.newState.chapters),
      },
    },
  }))(playSessionResponse);
};

export const mapPlayerSessionChapterToLegacyStates = (status: string) => {
  switch (status) {
    case undefined:
    case FsmChapterStatus.READY:
      return ChapterStatus.NONE;
    case FsmChapterStatus.ON_GOING:
      return ChapterStatus.ON_GOING;
    case FsmChapterStatus.VERIFIED:
      return ChapterStatus.VERIFIED;
    case FsmChapterStatus.QUIZ_READY:
      return ChapterStatus.QUIZ_READY;
    case FsmChapterStatus.COMPLETED:
      return ChapterStatus.COMPLETED;
    default:
      throw Error(`unknown chapter status ${status}`);
  }
};
