import { Box, Grid, Typography } from '@mui/material';

import { Link } from '@/containers';

export const Speaker = ({ speaker }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      key={speaker.name}
      sx={styles.speakerContainer}>
      <Link to={speaker.companyUrl} target="_blank">
        <Box
          alt="company logo"
          sx={[
            styles.companyLogo,
            {
              backgroundImage: `url(${speaker.companyLogo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            },
          ]}
        />
      </Link>
      <Box ml={2} pr={1}>
        <Typography sx={{ color: 'gray' }}>{speaker.name}</Typography>
        <Typography sx={{ color: 'gray' }}>{speaker.title}</Typography>
        {speaker.companyUrl ? (
          <Link to={speaker.companyUrl} target="_blank">
            {speaker.companyName}
          </Link>
        ) : (
          <Typography sx={{ color: 'gray' }}>{speaker.companyName}</Typography>
        )}
      </Box>
    </Grid>
  );
};

const styles = {
  speakerContainer: theme => ({
    display: 'flex',
    marginBottom: 2,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  }),
  companyLogo: theme => ({
    width: 77,
    height: 77,
    border: `solid 1px ${theme.palette.grayBrightLight}`,
  }),
};
