export const filterEpisodeBannerCategories = ({
  categories = [],
  episode,
  userRegion,
}) => {
  return categories.filter(category => {
    if (category.name === 'Ethics' || category.name === 'Video') return false;
    if (userRegion === 'USA') {
      return episode.fieldOfStudyId
        ? category.fieldsOfStudy?.includes(episode.fieldOfStudyId)
        : false;
    } else {
      return episode.categories?.includes(category.id);
    }
  });
};
