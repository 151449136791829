import Box from '@mui/material/Box';
import { useState } from 'react';
// reacts swipeable views isn't compatible with react 18
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const BannerSlide = ({ children }) => {
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [hover, setHover] = useState(false);

  const onChange = index => setCarouselPosition(index);
  const onMouseLeave = () => setHover(false);
  const onMouseEnter = () => setHover(true);

  return (
    <Box
      sx={styles.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <AutoPlaySwipeableViews
        index={carouselPosition}
        onChangeIndex={onChange}
        // HACK setting autoplay to false on hover causes Safari flicker issues
        // (forces refetch of resources) this simulates a pause for 2 min on hover
        interval={hover ? 120000 : 6000}
        disableLazyLoading
        enableMouseEvents>
        {children}
      </AutoPlaySwipeableViews>
      <Box sx={styles.dotsContainer}>
        {Array.from({ length: children.length }).map((_, index) => (
          <Box
            key={index}
            onClick={() => setCarouselPosition(index)}
            sx={[
              styles.dot,
              {
                bgcolor: index === carouselPosition ? 'white' : 'grayBright',
              },
            ]}
          />
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 1.5,
    overflow: 'hidden',
  },
  dotsContainer: {
    position: 'absolute',
    display: 'flex',
    right: 0,
    mr: 2,
    mt: 2,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    ml: 1,
    cursor: 'pointer',
  },
};
