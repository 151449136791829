import { Cancel, CheckCircle } from '@mui/icons-material';
import { Paper, Step, StepLabel, Stepper } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modals } from '@/constants';
import { AnalyticsService, EVENTS } from '@/services';
import {
  selectActiveModal,
  selectActiveModalParams,
  useFetchTaxRatesQuery,
  useFetchUserProfileQuery,
  usePurchasePackageMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { AppModal } from '../AppModal';
import {
  ConfirmSelection,
  PaymentCompleted,
  PaymentError,
  PaymentForm,
} from './components';

const Steps = {
  CONFIRM_SELECTION: 0,
  PAYMENT_METHOD: 1,
  COMPLETE_PURCHASE: 2,
  ERROR: 3,
};

export const PurchaseModal = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [activeStep, setActiveStep] = useState(Steps.CONFIRM_SELECTION);
  const [province, setProvince] = useState('');
  const [totalCost, setTotalCost] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [purchasePackage] = usePurchasePackageMutation();

  const activeModal = useSelector(selectActiveModal);
  const { offer } = useSelector(selectActiveModalParams) || {};

  const skipArg = activeModal?.name === Modals.PURCHASE ? undefined : skipToken;
  const { data: taxRates } = useFetchTaxRatesQuery(skipArg);
  const { data: userProfile } = useFetchUserProfileQuery(skipArg);

  const onCancel = () => {
    setActiveStep(Steps.CONFIRM_SELECTION);
    setProvince('');
  };

  const onCharge = async () => {
    setIsSubmitting(true);

    AnalyticsService.logEvent(EVENTS.PLAN_EVENTS.PURCHASE.INTENT, {
      userProfile: userProfile,
      userOffer: offer,
    });

    try {
      const card = elements.getElement(CardElement);
      const name = `${userProfile.firstName} ${userProfile.lastName}`;

      const { token, error: stripeError } = await stripe.createToken(card, {
        name,
      });

      if (stripeError) {
        SnackbarUtils.error(stripeError?.message || 'Something went wrong.');
        return;
      }

      const { error } = await purchasePackage({
        sku: offer.sku,
        province,
        token: token.id,
      });

      if (error) {
        AnalyticsService.logEvent(EVENTS.PLAN_EVENTS.PURCHASE.FAIL, {
          userProfile: userProfile,
          userOffer: offer,
          error: error?.message,
        });
        throw new Error(error);
      }

      AnalyticsService.logEvent(EVENTS.PLAN_EVENTS.PURCHASE.SUCCESS, {
        userProfile: userProfile,
        userOffer: offer,
      });
      setActiveStep(Steps.COMPLETE_PURCHASE);
    } catch (error) {
      setActiveStep(Steps.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStepIcon = step => {
    if (step === Steps.COMPLETE_PURCHASE && activeStep === Steps.ERROR)
      return <Cancel sx={{ color: 'red' }} />;
    return <CheckCircle sx={{ color: activeStep >= step ? 'teal' : 'gray' }} />;
  };

  return (
    <AppModal
      modalName={Modals.PURCHASE}
      onClose={onCancel}
      disableBackdropClick={
        isSubmitting || activeStep === Steps.COMPLETE_PURCHASE
      }
      maxWidth={false}>
      <Paper sx={styles.paper}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            width: '-webkit-fill-available',
            p: 0.5,
            mb: 2,
          }}>
          <Step key={'Confirm Selection'}>
            <StepLabel
              StepIconComponent={() => getStepIcon(Steps.CONFIRM_SELECTION)}>
              Order Summary
            </StepLabel>
          </Step>
          <Step key={'Add Credit Card'}>
            <StepLabel
              StepIconComponent={() => getStepIcon(Steps.PAYMENT_METHOD)}>
              Payment Method
            </StepLabel>
          </Step>
          <Step key={'Done'}>
            <StepLabel
              error={activeStep === Steps.ERROR}
              StepIconComponent={() => getStepIcon(Steps.COMPLETE_PURCHASE)}>
              Complete Purchase
            </StepLabel>
          </Step>
        </Stepper>
        {activeStep === Steps.CONFIRM_SELECTION && (
          <ConfirmSelection
            taxRates={taxRates}
            province={province}
            offer={offer}
            setProvince={setProvince}
            onConfirm={total => {
              setTotalCost(total);
              setActiveStep(Steps.PAYMENT_METHOD);
            }}
            onClose={onCancel}
          />
        )}
        {activeStep === Steps.PAYMENT_METHOD && (
          <PaymentForm
            isLoading={isSubmitting}
            totalCost={totalCost}
            onCharge={onCharge}
            onClose={onCancel}
          />
        )}
        {activeStep === Steps.COMPLETE_PURCHASE && (
          <PaymentCompleted onClose={onCancel} />
        )}
        {activeStep === Steps.ERROR && (
          <PaymentError
            onClose={onCancel}
            onBack={() => setActiveStep(Steps.PAYMENT_METHOD)}
          />
        )}
      </Paper>
    </AppModal>
  );
};

const styles = {
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    p: 5,
    maxWidth: 600,
    minWidth: {
      xs: 'auto',
      md: 600,
    },
  },
};
