import { CardGiftcard } from '@mui/icons-material';
import { Box, Hidden } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { getDisablePurchasing } from '@/store';

export const NavbarPricing = () => {
  const navigate = useNavigate();
  const disablePurchasing = useSelector(getDisablePurchasing);
  if (disablePurchasing) return null;

  return (
    <Hidden mdDown>
      <Box sx={{ mx: 1 }}>
        <Button
          onClick={() => navigate(Routes.PRICING)}
          startIcon={<CardGiftcard />}
          variant="dark"
          label="Pricing"
          fullWidth={false}
        />
      </Box>
    </Hidden>
  );
};
