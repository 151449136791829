import { Box, Typography } from '@mui/material';

export const Glossary = ({ episode }) => {
  const sortedGlossary = episode.glossary.slice().sort?.((first, second) => {
    const nameA = first.term.toUpperCase();
    const nameB = second.term.toUpperCase();
    if (nameA === nameB) {
      return 0;
    }

    return nameA < nameB ? -1 : 1;
  });

  return (
    <>
      {sortedGlossary?.map((item, idx) => (
        <Box key={idx.toString()} sx={styles.glossaryRow}>
          <Typography
            display="inline"
            variant="h6">{`${item.term}: `}</Typography>
          <Typography display="inline">{item.definition}</Typography>
        </Box>
      ))}
    </>
  );
};

const styles = {
  glossaryRow: {
    pb: 2,
  },
};
