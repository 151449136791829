import { useSelector } from 'react-redux';

import {
  selectIsFsmPlayer,
  selectSessionUserId,
  useFetchEpisodeChapterProgressQuery,
  useFindPlayerSessionByEpisodeIdQuery,
} from '@/store';

/** Temporary Hook - after migration this should be refactored into the consuming components */
export const useFetchEpisodeChapterProgress = (episodeId: string) => {
  const isFsmPlayer = useSelector(selectIsFsmPlayer);
  const userId = useSelector(selectSessionUserId);

  const episodeChapterProgressLegacy = useFetchEpisodeChapterProgressQuery(
    { episodeId },
    { skip: !userId || isFsmPlayer },
  );

  const episodeChapterProgressFsm = useFindPlayerSessionByEpisodeIdQuery(
    { episodeId },
    { skip: !userId || !isFsmPlayer },
  );

  return isFsmPlayer ? episodeChapterProgressFsm : episodeChapterProgressLegacy;
};
