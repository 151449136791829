import { BuildOutlined } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';

import { Button } from '@/components';
import { urls } from '@/constants';

export const PageErrorBoundary = () => {
  const onClick = () => {
    window.location.reload();
  };

  return (
    <Paper
      sx={{
        width: { xs: '100%', md: 640 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        p: 2,
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          An Unexpected Error Occurred
        </Typography>
        <BuildOutlined fontSize="large" />
      </Box>
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        Our engineers have been notified and are working on a solution. Please
        try to reload the page. If the problem persists, you can reach out to
        support at <b>{urls.supportEmail}</b>.
      </Typography>
      <Button label="Refresh" variant="warning" onClick={onClick} />
    </Paper>
  );
};
