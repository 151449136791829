import {
  AccountCircle as AccountCircleIcon,
  Assessment as AssessmentIcon,
  Directions as DirectionsIcon,
  Email as EmailIcon,
  Explore as ExploreIcon,
  Flag as FlagIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  PhoneAndroid,
  PhoneIphone,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import {
  Box,
  Hidden,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modals, RoutesConfig, SidebarItems, urls } from '@/constants';
import { browseIcon, browseIconSelected } from '@/images';
import { AnalyticsService, BUTTON_NAME } from '@/services';
import { selectSessionUserId, uiActions } from '@/store';
import { login, logout } from '@/utils';

const styles = {
  browseIcon: {
    height: theme => theme.typography.pxToRem(18),
    width: theme => theme.typography.pxToRem(18),
    ml: 0.25,
  },
};

const SidebarIcons = {
  [SidebarItems.LEARNING_PATHS.PATH]: <DirectionsIcon />,
  [SidebarItems.TECHNICAL.PATH]: <AssessmentIcon />,
  [SidebarItems.DISCOVER.PATH]: <ExploreIcon />,
  [SidebarItems.LIBRARY.PATH]: <ViewModuleIcon />,
  [SidebarItems.TRACKER.PATH]: <FlagIcon />,
  [SidebarItems.MY_LUMIQ.PATH]: {
    // TODO: Replace with SVG brand icon
    regular: (
      <Box component="img" sx={styles.browseIcon} src={browseIcon} alt="icon" />
    ),
    selected: (
      <Box
        component="img"
        sx={styles.browseIcon}
        src={browseIconSelected}
        alt="icon"
      />
    ),
  },
};

export const SidebarList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = useSelector(selectSessionUserId);

  const onListItemClick = item => {
    dispatch(uiActions.toggleSidebarDrawer());
    navigate(item.PATH);
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');

  return (
    <List sx={{ pt: 0, pb: 10, color: 'text.white' }}>
      {Object.values(SidebarItems).map(item => {
        const isSelected = pathname === item.PATH;
        const Icon =
          SidebarIcons[item.PATH][isSelected ? 'selected' : 'regular'] ||
          SidebarIcons[item.PATH];

        return (
          <SidebarItem
            key={item.PATH}
            Icon={Icon}
            name={item.NAME}
            isSelected={isSelected}
            onClick={() => onListItemClick(item)}
          />
        );
      })}
      <Hidden mdUp>
        {userId ? (
          <>
            <SidebarItem
              Icon={<AccountCircleIcon />}
              name={RoutesConfig.PROFILE.NAME}
              isSelected={pathname === RoutesConfig.PROFILE.PATH}
              onClick={() => {
                onListItemClick(RoutesConfig.PROFILE);
              }}
            />
            <SidebarItem Icon={<LogoutIcon />} name="Logout" onClick={logout} />
          </>
        ) : (
          <SidebarItem Icon={<LoginIcon />} name="Login" onClick={login} />
        )}
        <SidebarItem
          Icon={<EmailIcon />}
          name="Support"
          onClick={() => {
            dispatch(uiActions.toggleSidebarDrawer());
            window.Intercom?.('show');
          }}
        />
      </Hidden>
      <SidebarItem
        Icon={isAndroid ? <PhoneAndroid /> : <PhoneIphone />}
        name="Download App"
        onClick={() => {
          AnalyticsService.buttonClick({ name: BUTTON_NAME.DOWNLOAD_APP });

          dispatch(uiActions.toggleSidebarDrawer());
          if (isMobile) {
            window.open(urls.downloadApp);
            return;
          }

          dispatch(uiActions.setActiveModal({ name: Modals.APP_DOWNLOAD }));
        }}
      />
    </List>
  );
};

const SidebarItem = ({ isSelected, Icon, onClick, name }) => (
  <ListItemButton
    onClick={onClick}
    sx={[
      {
        borderLeft: 6,
        borderColor: 'transparent',
      },
      isSelected && {
        bgcolor: 'blueDark',
        borderColor: 'aqua',
      },
    ]}>
    <ListItemIcon sx={{ color: isSelected ? 'aqua' : 'text.white' }}>
      {Icon}
    </ListItemIcon>
    <ListItemText
      primary={name}
      primaryTypographyProps={{
        sx: [
          !isSelected && { color: 'blueBlackLight' },
          isSelected && { fontWeight: 700 },
        ],
      }}
    />
  </ListItemButton>
);
