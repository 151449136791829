import { Button } from '@mui/material';

export const AppButton = ({ children, variant = 'primary', sx, ...params }) => {
  return (
    <Button sx={[styles.base, styles[variant], sx]} {...params}>
      {children}
    </Button>
  );
};

const styles = {
  base: {
    minHeight: 50,
    textTransform: 'none',
    p: '12px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textWrap: 'nowrap',
  },
  primary: theme => ({
    color: 'blueBlack',
    borderColor: 'blueBlack',
    border: `1px solid ${theme.palette.blueBlack}`,
  }),
  secondary: theme => ({
    color: 'white',
    border: `1px solid ${theme.palette.white}`,
  }),
  orange: theme => ({
    color: 'white',
    background: theme.gradients.orange,
    border: 'none',
  }),
  teal: theme => ({
    color: 'white',
    background: theme.gradients.teal,
    border: 'none',
  }),
};
