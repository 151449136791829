import { Pill } from '@/components/Pill';
import { convertSecondsToMinutes } from '@/utils';

export const EthicsPill = ({ region, ethicsDuration, showDuration = true }) => {
  return (
    <Pill
      size="small"
      color="success"
      label={
        !showDuration
          ? 'ETHICS'
          : region === 'USA'
            ? 'ETHICS'
            : `ETHICS ${convertSecondsToMinutes(ethicsDuration)} mins`
      }
    />
  );
};
