import { Box } from '@mui/material';
import { useContext } from 'react';

import { Button } from '@/components';

import { OnboardingFormStepsContext } from '../OnboardingForm';
import { FOOTER_HEIGHT } from '../styles';
import { ProgressBar } from './ProgressBar';

export const Footer = ({
  handlePrev,
  handleNext,
  isPrevButtonDisabled,
  isNextButtonDisabled,
}) => {
  const { activeStep, steps } = useContext(OnboardingFormStepsContext);

  const isFirstStep = steps[0].id === activeStep;
  const isLastStep = steps[steps.length - 1].id === activeStep;
  const currentStepIndex = steps.findIndex(step => step.id === activeStep);
  const totalSteps = steps.length - 1;

  const nextButtonCopy = isFirstStep
    ? 'Get Started'
    : isLastStep
      ? 'Explore LumiQ'
      : 'Next';

  return (
    <Box
      sx={theme => ({
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: FOOTER_HEIGHT,
        width: '100%',
        backgroundColor: 'white',
        py: 1.5,
        px: 2,
        boxShadow: theme.customShadows.modalFooter,
        zIndex: 1,
      })}>
      <ProgressBar current={currentStepIndex} total={totalSteps} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          gap: 1.5,
        }}>
        {handlePrev && (
          <Button
            variant="secondary"
            onClick={handlePrev}
            disabled={isPrevButtonDisabled}
            label="Previous"
          />
        )}
        {handleNext && (
          <Button
            onClick={handleNext}
            disabled={isNextButtonDisabled}
            label={nextButtonCopy}
          />
        )}
      </Box>
    </Box>
  );
};
