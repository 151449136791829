import { Box, Typography } from '@mui/material';

import { EpisodeRowMobile } from '../EpisodeRowMobile';

export const EpisodeTableMobile = ({ episodes, referrer }) => (
  <Box>
    {episodes?.length ? (
      episodes.map((episode, index) => (
        <EpisodeRowMobile
          key={episode.episodeId}
          episode={episode}
          index={index}
          referrer={referrer}
        />
      ))
    ) : (
      <Typography
        variant="pill2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 2,
        }}>
        No episodes found.
      </Typography>
    )}
  </Box>
);
