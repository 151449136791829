import { Box, Typography } from '@mui/material';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { Routes } from '@/constants';
import { Link } from '@/containers';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import { selectUserRegion, useFetchEpisodesQuery } from '@/store';
import { createRouteWithParams } from '@/utils';

export const RelatedEpisodes = ({ relatedEpisodes, referrer }) => {
  const region = useSelector(selectUserRegion);
  const { data: episodes, isFetching } = useFetchEpisodesQuery({
    episodeIds: relatedEpisodes,
    region,
  });
  if (!episodes?.length || isFetching) return null;
  return (
    <Box>
      <Typography variant="h6" mt={2} sx={{ color: 'gray' }}>
        Related Episodes
      </Typography>
      {episodes.map(episode => (
        <InView
          key={episode.episodeId}
          triggerOnce
          onChange={inView => {
            if (inView) {
              AnalyticsService.episodeImpression({
                episode,
                location: {
                  ...referrer,
                  component: EVENT_CONSTANTS.COMPONENT.RELATED_EPISODE,
                },
                referrer,
              });
            }
          }}>
          <Box mt={1} mb={1}>
            <Link
              to={{
                pathname: createRouteWithParams(Routes.EPISODE, {
                  episodeId: episode.episodeId,
                }),
              }}
              state={{
                referrer: {
                  ...referrer,
                  component: EVENT_CONSTANTS.COMPONENT.RELATED_EPISODE,
                  relatedEpisodeId: episode.episodeId,
                  relatedEpisodeName: episode.name,
                },
              }}>
              {episode.name}
            </Link>
          </Box>
        </InView>
      ))}
    </Box>
  );
};
