import * as Sentry from '@sentry/react';

import config from '@/config';
import { urls } from '@/constants';

import { isData, isError, isString } from './utils';

export const initLogger = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tunnel: urls.actions.sentry,
  });
};

export const log = (...events: any[]) => {
  try {
    const messages = events.filter(isString);
    const objects = events.filter(isData);
    const errors = events.filter(isError);

    // breadcrumbs must be logged before exception is captured
    messages.forEach(m => logBreadcrumb({ message: m }));
    objects.forEach(o => logBreadcrumb({ data: o }));
    errors.forEach(e => Sentry.captureException(e));

    // fallback: if Sentry.captureException is never called, the breadcrumbs don't get logged
    if (errors.length === 0) {
      Sentry.captureException('No other Error was provided');
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const logBreadcrumb = ({
  message,
  data,
}: {
  message?: string;
  data?: Record<string, any>;
}) => {
  try {
    const payload = {
      message,
      level: 'debug' as Sentry.SeverityLevel,
      data,
    };

    Sentry.addBreadcrumb(payload);
  } catch (error) {
    Sentry.captureException(error);
  }
};
