import { Box, Typography } from '@mui/material';

export const KeyTakeaways = ({ episode }) => {
  const { takeaways } = episode;
  return takeaways?.map((takeaway, index) => (
    <Box key={index}>
      <Typography
        variant="body3"
        sx={{ fontWeight: 'bold', color: 'blueBlack' }}>
        {index + 1})
      </Typography>
      <Typography variant="body3" sx={{ ml: 0.5, color: 'blueBlack' }}>
        {takeaway}
      </Typography>
    </Box>
  ));
};
