import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals } from '@/constants';
import { alarm, ticketDisabled } from '@/images';
import { selectIsB2CUser, selectUserRegion, uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const PlanUsedUpModal = () => {
  const dispatch = useDispatch();
  const region = useSelector(selectUserRegion);
  const isB2CUser = useSelector(selectIsB2CUser);
  return (
    <AppModal modalName={Modals.PLAN_USED_UP}>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        <img
          src={region === 'USA' ? ticketDisabled : alarm}
          alt="Plan Used Up"
          style={{ maxWidth: 114 }}
        />
        <Typography variant="h3" mt={3}>
          You have used up your remaining{' '}
          {region === 'USA' ? 'credits' : 'minutes'}
        </Typography>
        <Box width={220} mt={4}>
          <Button
            variant="warning"
            label={isB2CUser ? 'Buy Credits' : 'Message Us to Get Credits'}
          />
        </Box>
        <Box width={220} mt={2}>
          <Button
            variant="secondary"
            label={'Close'}
            onClick={() => dispatch(uiActions.closeActiveModal())}
          />
        </Box>
      </Box>
    </AppModal>
  );
};
