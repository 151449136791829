import { VideoLibraryOutlined as VideoLibraryOutlinedIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Container,
  Hidden,
  Skeleton,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircleStatusIcon, EpisodeGrid } from '@/components';
import { CreditsDurationInfo, EpisodeCount } from '@/containers';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectUserEpisodes,
  selectUserRegion,
  useFetchEpisodesQuery,
  useFetchLearningPathQuery,
  userEpisodesSelectors,
} from '@/store';

import { BackButton } from './BackButton';
import { CoverImage } from './CoverImage';

export const LearningPath = () => {
  const userRegion = useSelector(selectUserRegion);
  const { isLoading: isUserEpisodesLoading } = useSelector(selectUserEpisodes);
  const episodeStatuses = useSelector(userEpisodesSelectors.selectEntities);

  const { learningPathId } = useParams();

  const { data: learningPath, isLoading: isLearningPathLoading } =
    useFetchLearningPathQuery({
      region: userRegion,
      learningPathId,
    });

  const { data: episodes, isLoading: isEpisodesLoading } =
    useFetchEpisodesQuery(
      {
        region: userRegion,
        episodeIds: learningPath?.episodes.map(e => e.episodeId),
      },
      {
        skip: !learningPath,
        selectFromResult: result => ({
          ...result,
          isLoading: result.isLoading || result.isUninitialized,
          data: R.indexBy(R.prop('episodeId'), result.data),
        }),
      },
    );

  const isLpEpisodeCardsLoading =
    isLearningPathLoading || isEpisodesLoading || isUserEpisodesLoading;

  const learningPathEpisodes = useMemo(() => {
    if (isLpEpisodeCardsLoading) {
      return [];
    }

    return learningPath.episodes.map(e => ({
      ...e,
      episode: episodes[e.episodeId],
      status: episodeStatuses[e.episodeId]?.status,
    }));
  }, [learningPath, episodes, episodeStatuses, isLpEpisodeCardsLoading]);

  const episodeCount = learningPath?.episodes?.length || 0;

  if (isLearningPathLoading) {
    return (
      <Container sx={styles.root}>
        <Box mb={6}>
          <Skeleton variant="rounded" height={200} />
        </Box>
        <Box mt={2}>
          <Typography sx={styles.sectionTitle}>Description</Typography>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
        <Box mt={2}>
          <Typography sx={styles.sectionTitle}>Key Takeaways</Typography>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
        <Box mt={2}>
          <Typography sx={styles.sectionTitle}>Episodes</Typography>
          <EpisodeGrid isLoading />
        </Box>
      </Container>
    );
  }

  if (!learningPath) {
    return (
      <Alert severity="error">
        <Typography variant="select">
          Sorry, the learning path you were looking for was not found.
        </Typography>
      </Alert>
    );
  }

  return (
    <Box sx={{ display: 'flex', color: 'blueBlack' }}>
      <Hidden smDown>
        <BackButton />
      </Hidden>

      <Box flexGrow={1}>
        <Box>
          <Box>
            <Hidden smDown>
              <CoverImage src={learningPath.coverImage} />
            </Hidden>
            <Box sx={{ display: 'flex' }}>
              <Hidden smUp>
                <BackButton />
              </Hidden>
              <Box>
                <Typography variant="h1" mb={3}>
                  {learningPath.title}
                </Typography>
                <Box
                  sx={{
                    mb: 2,
                    display: 'flex',
                    gap: 2,
                  }}>
                  <CreditsDurationInfo
                    credits={learningPath.credits}
                    duration={learningPath.duration}
                  />
                  <EpisodeCount episodeCount={episodeCount} />
                </Box>
              </Box>
            </Box>

            <Box mb={4}>
              <Hidden smUp>
                <CoverImage src={learningPath.coverImage} />
              </Hidden>
              <Typography variant="h2" gutterBottom>
                Description
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ '& p': { margin: 0 } }}
                dangerouslySetInnerHTML={{ __html: learningPath.description }}
              />
            </Box>
          </Box>
        </Box>
        {learningPath.keyTakeaways && (
          <Box mb={4}>
            <Typography variant="h2" gutterBottom>
              Key Takeaways
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                '& ul': {
                  margin: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingInlineStart: 2,
                },
                '& li': {
                  pl: 0,
                  ml: 0,
                  mr: 4,
                  mb: 0,
                  width: { xs: '100%', md: '45%' },
                },
              }}
              dangerouslySetInnerHTML={{ __html: learningPath.keyTakeaways }}
            />
          </Box>
        )}
        <Box mb={4}>
          <Typography sx={styles.sectionTitle}>
            Learning Path Progress
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {!!episodeStatuses &&
              learningPathEpisodes.map((lpe, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 3,
                    '& > *': {
                      mb: 2,
                    },
                  }}>
                  <Typography variant="caption">{idx + 1}</Typography>
                  <VideoLibraryOutlinedIcon fontSize="small" />
                  <CircleStatusIcon status={lpe.status} sx={{ width: 16 }} />
                </Box>
              ))}
          </Box>
        </Box>
        <Box>
          <Typography sx={styles.sectionTitle}>Episodes</Typography>
          {isLpEpisodeCardsLoading &&
            Array.from({ length: 3 }).map((_, idx) => (
              <Skeleton
                key={idx}
                variant="rounded"
                height={200}
                sx={{ mb: 2 }}
              />
            ))}
          {!isLpEpisodeCardsLoading && (
            <EpisodeGrid
              episodeIds={learningPath.episodes.map(e => e.episodeId)}
              referrer={{
                page: EVENT_CONSTANTS.PAGE.LEARNING_PATH_DETAILS,
                component: EVENT_CONSTANTS.COMPONENT.LEARNING_PATH_EPISODE,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  sectionTitle: theme => ({
    ...theme.typography.h2,
    color: 'blueBlack',
    mb: 1,
  }),
};
