import { Growthbook } from './Growthbook';
import { Intercom } from './Intercom';
import { LogRocket } from './LogRocket';
import { MixPanel } from './MixPanel';

export const ToolWrapper = () => {
  return (
    <>
      <Intercom />
      <LogRocket />
      <MixPanel />
      <Growthbook />
    </>
  );
};
