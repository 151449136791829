import { CloudDownload } from '@mui/icons-material';
import { Alert, Box, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button, ButtonProgressSpinner } from '@/components';
import { urls } from '@/constants';
import { Link } from '@/containers';
import { lumiqWhiteLogoBig } from '@/images';
import {
  selectUserRegion,
  useDownloadCertificateMutation,
  useDownloadCertificateSummaryMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const CertificateDownload = () => {
  const location = useLocation();
  const userRegion = useSelector(selectUserRegion);

  const [downloadCertificateSummary, { isLoading: isSummaryDownloading }] =
    useDownloadCertificateSummaryMutation();

  const [downloadCertificate, { isLoading: isCertificateDownloading }] =
    useDownloadCertificateMutation();

  const isLoading = isSummaryDownloading || isCertificateDownloading;

  const query = new URLSearchParams(location.search);
  const { token } = Object.fromEntries(query);

  let decodedToken = {};
  let errMsg;

  try {
    decodedToken = jwtDecode(token);
    if (Date.now() > decodedToken.exp * 1000) {
      errMsg = 'Token Expired';
    }
  } catch (err) {
    errMsg = 'Invalid Token';
  }

  const { access } = decodedToken;
  const isSingleCertificateDownload = access?.scopes?.includes(
    'downloadCertificate',
  );
  const pdLabel = userRegion === 'USA' ? 'CPE' : 'CPD';

  const onDownload = async () => {
    if (isSingleCertificateDownload) {
      const { error } = await downloadCertificate({
        certificateId: access.pdHourId,
      });
      if (error) SnackbarUtils.error(error);
    } else {
      const { error } = await downloadCertificateSummary({
        fromDate: access.fromDate,
        toDate: access.toDate,
      });
      if (error) SnackbarUtils.error(error);
    }
  };

  return (
    <>
      <Box sx={styles.header}>
        <Box
          component="img"
          src={lumiqWhiteLogoBig}
          sx={styles.logo}
          alt="lumiq logo"
        />
      </Box>
      <Box sx={styles.container}>
        <Typography variant="h3">Download Documentation</Typography>
        <Box sx={styles.paper}>
          {errMsg ? (
            <Alert severity="error">{errMsg}</Alert>
          ) : (
            <>
              <Typography>
                You can download the {pdLabel} documentation you requested by
                clicking the button below.
              </Typography>
              <Box sx={styles.buttonContainer}>
                <Button
                  label="Download"
                  startIcon={
                    isLoading ? <ButtonProgressSpinner /> : <CloudDownload />
                  }
                  fullWidth={false}
                  disabled={isLoading}
                  onClick={onDownload}
                />
              </Box>
              <Typography component="span">
                If you require any assistance, or if you have any feedback to
                share, please don&apos;t hesitate to contact us at&nbsp;
              </Typography>
              <Link inline to={`mailto:${urls.supportEmail}`}>
                {urls.supportEmail}
              </Link>
              <Typography component="span">.</Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme => theme.gradients.primary,
    height: 60,
    width: '100%',
  },
  logo: {
    height: 'auto',
    width: 120,
  },
  container: {
    p: 3,
    maxWidth: 1160,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    mx: 'auto',
  },
  paper: {
    mt: 3,
    borderRadius: 2,
    border: theme => `1px solid ${theme.palette.grayBrightLight}`,
    boxShadow: theme => `0 2px 5px 0 ${theme.palette.grayBrightLight}`,
    boxSizing: 'border-box',
    p: 3,
    width: '100%',
  },
  buttonContainer: {
    my: 3,
  },
};
