import {
  FormatOptions,
  NASBA_CATEGORY_TABS,
  SortByOptions,
  SortByOptionsNasba,
  SortByOptionsNonNasba,
} from '@/constants';

export const formatLibraryPageFilters = ({
  filterList,
  isNasba,
  categories,
}) => {
  const {
    sortBy,
    format,
    selectedFos,
    selectedCategories,
    selectedNasbaCategories,
    selectedTab,
    onlyEthics,
  } = filterList;

  let orderBy = {};
  const isValidSortBy = Object.keys(
    isNasba ? SortByOptionsNasba : SortByOptionsNonNasba,
  ).includes(sortBy);
  if (!isValidSortBy) {
    orderBy = { order: 'publish_date.desc' };
  } else if (sortBy === SortByOptions.ALPHABETICAL.key) {
    orderBy = { order: 'name.asc' };
  } else if (sortBy === SortByOptions.DURATION_ASC.key) {
    orderBy = { order: 'duration.asc' };
  } else if (sortBy === SortByOptions.DURATION_DESC.key) {
    orderBy = { order: 'duration.desc' };
  } else if (sortBy === SortByOptions.CREDIT_ASC.key) {
    orderBy = { order: 'credits.asc' };
  } else if (sortBy === SortByOptions.CREDIT_DESC.key) {
    orderBy = { order: 'credits.desc' };
  } else {
    orderBy = { order: 'publish_date.desc' };
  }

  let formatFilter = {};
  if (format === FormatOptions.AUDIO.key) {
    formatFilter = { is_video: 'is.false' };
  } else if (format === FormatOptions.VIDEO.key) {
    formatFilter = { is_video: 'is.true' };
  }

  let categoryFilter = {};
  if (!isNasba && selectedCategories?.length) {
    categoryFilter = { categories: `ov.{${selectedCategories.join(',')}}` };
  }

  let fosFilter = {};
  if (
    isNasba &&
    selectedTab === NASBA_CATEGORY_TABS[1].value &&
    selectedFos?.length
  ) {
    fosFilter = { field_of_study_id: `in.(${selectedFos.join(',')})` };
  }
  if (
    isNasba &&
    selectedTab === NASBA_CATEGORY_TABS[0].value &&
    selectedNasbaCategories?.length
  ) {
    const fieldsOfStudy = [];
    selectedNasbaCategories.forEach(categoryId => {
      fieldsOfStudy.push(...(categories[categoryId]?.fieldsOfStudy || []));
    });
    if (fieldsOfStudy.length) {
      fosFilter = { field_of_study_id: `in.(${fieldsOfStudy.join(',')})` };
    }
  }

  let ethicsFilter = {};
  if (!isNasba && onlyEthics) {
    ethicsFilter = { total_ethics: 'not.is.null' };
  }

  return {
    ...orderBy,
    ...formatFilter,
    ...ethicsFilter,
    ...fosFilter,
    ...categoryFilter,
  };
};
