import { Box } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { CheckboxButton } from '@/components';
import { urls } from '@/constants';
import {
  selectNonDesignatedCountryOptions,
  useFetchDesignationOptionsQuery,
} from '@/store';

import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';

export const NonDesignatedRegion = ({ goToPrevStep, goToNextStep }) => {
  const { nonDesignatedCountryOptions, isLoading } =
    useFetchDesignationOptionsQuery(undefined, {
      selectFromResult: result => ({
        ...result,
        nonDesignatedCountryOptions: selectNonDesignatedCountryOptions(
          result.data,
        ),
      }),
    });

  const [form, setForm] = useState();

  // TODO
  if (isLoading) return null;

  return (
    <Box sx={styles.formContainer}>
      <Header Title={<Title />} Subtitle={<Subtitle />} />
      <Box sx={styles.formPrimarySection}>
        {Object.keys(nonDesignatedCountryOptions).map(countryCode => (
          <Box key={countryCode} sx={styles.checkboxContainer}>
            <CheckboxButton
              label={nonDesignatedCountryOptions[countryCode].name}
              checked={form === countryCode}
              onChange={() => setForm(countryCode)}
            />
          </Box>
        ))}
      </Box>
      <Footer
        handleNext={() => goToNextStep(form)}
        handlePrev={() => goToPrevStep(form)}
        isNextButtonDisabled={!isFormValid(form)}
      />
    </Box>
  );
};

const isFormValid = form => !R.isNil(form);

const Title = () => <ModalTitle>Please select your country.</ModalTitle>;

const Subtitle = () => (
  <ModalSubtitle>
    Don&apos;t see your country listed? Reach out to {urls.supportEmail}
  </ModalSubtitle>
);
