import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PageTabs } from '@/components';
import {
  selectAssessmentReadyEpisodeIds,
  selectIsFsmPlayer,
  selectSessionUserId,
  selectUserRegion,
  useFetchUserQuizzesQuery,
} from '@/store';
import { useFetchSessionQuizzesQuery } from '@/store/player-fsm-api';

import {
  AssessmentReady,
  Bookmarked,
  Completed,
  InProgress,
  QuizReady,
} from './components';
import { selectTabs } from './utils';

export const MyLumiq = () => {
  const location = useLocation();

  const userId = useSelector(selectSessionUserId);
  const userRegion = useSelector(selectUserRegion);

  const isFSMPlayerUser = useSelector(selectIsFsmPlayer);

  const { incompleteQuizCount: quizCountOld } = useFetchUserQuizzesQuery(
    undefined,
    {
      skip: !userId || isFSMPlayerUser,
      selectFromResult: result => ({
        ...result,
        incompleteQuizCount: result.data?.filter(i => !i.completed).length,
      }),
    },
  );

  const { incompleteQuizCount: quizCountNew } = useFetchSessionQuizzesQuery(
    undefined,
    {
      skip: !userId || !isFSMPlayerUser,
      selectFromResult: result => ({
        ...result,
        incompleteQuizCount: result.data?.reduce(
          (acc, e) => acc + e.availableQuizCount,
          0,
        ),
      }),
    },
  );

  const incompleteQuizCount = isFSMPlayerUser ? quizCountNew : quizCountOld;

  const incompleteAssessmentsCount = useSelector(
    selectAssessmentReadyEpisodeIds,
  )?.length;

  const tabs = useMemo(
    () =>
      selectTabs({
        userRegion,
        incompleteQuizCount,
        incompleteAssessmentsCount,
      }),
    [userRegion, incompleteQuizCount, incompleteAssessmentsCount],
  );

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  useEffect(() => {
    if (location.state?.tab) {
      const newSelectedTab = tabs.findIndex(
        tab => tab.value === location.state.tab,
      );
      setSelectedTab(tabs[newSelectedTab].value);
      location.state.tab = null;
    }
  }, [location, tabs]);

  return (
    <Box>
      <Box mb={2.5}>
        <Typography variant="h1">My LumiQ</Typography>
      </Box>
      <Box mb={2.5}>
        <PageTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={(e, newVal) => setSelectedTab(newVal)}
        />
      </Box>

      <Box>
        {selectedTab === tabs[0].value && <InProgress />}
        {userRegion === 'CAN' && selectedTab === tabs[1].value && <QuizReady />}
        {userRegion === 'USA' && selectedTab === tabs[1].value && (
          <AssessmentReady />
        )}

        {selectedTab === tabs[2].value && <Bookmarked />}
        {selectedTab === tabs[3].value && <Completed />}
      </Box>
    </Box>
  );
};
